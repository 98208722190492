import "./ErrorPage.css";
import Logo from "./Logo.png";

function ErrorPage() {
    return (
      <div className="errorPage">
            <div className="errorContainer">
                <img src={Logo} alt="" width={277.38} height={90}/>
                <div className="errorTitle"> În construcție </div>
                <div className="errorContent">
                Lucrăm intensiv pentru a face site-ul disponibil.<br/>
                Până atunci, pentru informații accesați site-ul: spielraum.md.
                </div>
                <div className="accessBtn"><a href="https://spielraum.md/">Accesează</a></div>             
            </div>
      </div>
    );
  }
  
  export default ErrorPage;