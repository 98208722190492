import Image from "../../components/Image";
import ToTopBtn from "../../components/toTopBtn/toTopBtn";
import foto_1 from "../../images/istoria/istoria_1.jpg"
import foto from "../../images/istoria/istoria_2.jpg"

export default function Istoria(){
    return<>
        <div className="page-body-content">
            <h1>Istoria din spatele EduCasa</h1>
            <p></p>
            
            <p>
            <span className="accent-color-1">Ianuarie 2021.</span> În plină pandemie își deschide larg ușile Centrul de educație timpurie – <span className="accent-color-1">KinderSpielRaum
după Emmi Pikler</span> – într-o locație mică din sectorul Buiucani în Chișinău, fondat de către pedagoga Pikler,
Ana Bordos-Boldișor, însoțită de către colega ei – Alla Ceban, la fel, pedagogă Pikler.
            </p>
            <p></p>
            <div className="row-img">
                <Image src={foto} phoneWidth="60%" phoneMaxWidth="250px" tabletWidth="46%" desktopWidth="33%"></Image>

                <div className="column">
                    Un concept de educație timpurie nou pentru țara noastră. Aici se desfășoară Cursul părinte-copil (de la 3
                    luni până la 36 luni), orientat după pedagogia Emmi Pikler. În KinderSpielRaum copiii găsesc un spațiu
                    pregătit corespunzător vârstelor sale, în care își pot satisface interesele  de mișcare, joacă și liniște.
                    <h3 style={{marginTop:"1em"}}>
                    Părinții au aici timpul să își observe copiii și să culeagă perspective noi. <span className="accent-color-1">Atelierele serale</span> dedicate
părinților pentru subiectele ce îi preocupă cu privire la educarea și îngrijirea copiilor, sunt sursa de
alimentare cu curaj, inspirație și încredere în ceea ce fac pentru copilașii lor.
                    </h3>
                    <h3 style={{marginTop:"1em"}}>Părinții sunt cei ce ne-au impulsionat să extindem serviciile noastre, pentru a fi prezenți în mult mai
multe case, familii, dar și pe o perioadă mai lungă de timp.</h3>
                </div>
            </div>

           
<p>
<span className="accent-color-1">Mai 2022.</span> Am mers pe calea <span className="accent-color-1">instruirii actorilor educaționali</span> de la alte instituții de educaționale private,
pentru a împărtăși cunoștințele și experiențele noastre. Istoricul modelului educațional timpuriu din țara
noastră, a lăsat amprente asupra adulților de astăzi, care necesită un suport continuu în activitatea lor de
actori educaționali. Actual avem o colaborare cu trei instituții educaționale- ceea ce se datorează atât
cererii părinților, cât și a managerilor acestor instituții.
</p>
<p>
<span className="accent-color-1">Martie 2023.</span> Este organizat primul <span className="accent-color-1">Curs de parenting blând după Pedagogia Pikler</span> în Chișinău cu
prezența fizică, dedicat părinților și viitorilor părinți. Feedback-ul pozitiv al părinților ne motivează să
continuăm acest concept.
</p>
<p></p>
<div className="row-img">
<Image src={foto_1} phoneWidth="80%" phoneMaxWidth="400px" tabletWidth="45%" desktopWidth="33%"></Image>

    <div className="column">
Cererea a tot mai multor părinți, de a ne vizita, și lipsa spațiului pentru extinderea activității, ne-a mișcat
spre un nou pas, de a găsi un spațiu mai încăpător, cu o mică curte.
<h3 style={{marginTop:"1em"}}>
    <span className="accent-color-1">Iunie 2023.</span> KinderSpielRaum (sală de joacă) devine <span className="accent-color-1">EduCasa</span>, odată cu stabilirea locației casei noi.
</h3>
    </div>
</div>
            <ToTopBtn></ToTopBtn>
        </div>
    </>
}

