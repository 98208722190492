import NavBar from "./NavBar";
import { Outlet } from "react-router-dom";
import Subsol from "../subsol/Subsol";

export function Layout(){
    return (
        <div className="page">
            <NavBar/>
            <div className="page-body">
                <Outlet/>
            </div>
            <Subsol/>
        </div>
    )
}
