import ToTopBtn from "../../components/toTopBtn/toTopBtn"
import UteFoto from "../../images/Ute-Strub.jpg"

export default function Ute(){
    return(
        <>
        <div className="page-body-content">
            <div className="header">
                    <div className="foto" style={{backgroundImage:`url(${UteFoto})`,borderRadius:"0.5em"}}></div>
                    <div className="column">
                        <h1>Ute Strub</h1>
                        <p>
                        Ute Strub, născută în 1933, este kinetoterapeută și fostă studentă a profesoarei de gimnastică Elfriede
Hengstenberg.
                        </p>
                        <p>
                        În 1991 și-a publicat cartea „Evoluțiile” cu descrieri și fotografii din munca ei cu copiii și, în același timp,
prin seminarele organizate pentru adulți și parțial pentru copii, a extins activitatea sa cu dispozitivele de
mișcare și echilibru dezvoltate de Hengstenberg în țările germanice.
                        </p>
                    </div>
                    
            </div>
            
            <p>
            Prin colaborarea ei cu psihologa pentru copii, Anna Tardos (fiica Emmei Pikler) din Budapesta, la
publicarea lucrărilor Emmei Pikler, precum și în cadrul prelegerilor, seminarelor și consilierii familiale
private, ea se pronunță de ani de zile pentru a face cunoscută educația timpurie după Emmi Pikler.
            </p>

            <p>
            Perspectivele și experiențele de a face față fricii, mâniei și durerii într-un mod diferit, pe care le-a câștigat
cu oamenii bolnavi și grav bolnavi, servesc drept bază pentru munca ei cu oamenii sănătoși de astăzi.
            </p>
            <p>
            În 1996, Ute Strub a dat impuls pentru mișcarea în camera de joacă (SpielRaum) în mai multe țări, care
oferă un cadru însoțit, în care părinții pot percepe bucuria de descoperire a bebelușilor, și a copiilor mici,
precum și propria lor activitate. Ea este cea care a dezvoltat conceptul spațiilor de joacă liberă și mișcare
autonomă după Pedagogia Pikler.
            </p>
            <p style={{fontStyle:"italic"}}>
            „Cum v-ați comporta dacă un pițigoi albastru, ar ciuguli niște boabe de pe pervazul ferestrei
voastre? Ați spune oare „aceste boabe, aici”? Nu, pur și simplu vă bucurați de ceea ce face
pasărea. De asemenea, puteți și aveți voie să vă bucurați de ceea ce face copilul vostru în aceste
ore aici. Încercați acest tip de reținere; rețineți-vă de sugestii și idei. Și fiți interesați, de ceea ce
experimentați voi apoi.”</p>
<p>
În 2010, a deschis Strandgut (moșia cu nisip) în Berlin-Schoeneberg, unde copiii din oraș se pot juca cu
nisip și ustensile de bucătărie în camere diferite, și se pot distra în paie. Conceptul cămarei cu nisip, a
fost ulterior preluat în mai multe centre educaționale orientate după pedagogia Pikler din țările
germanice. Este o extindere a Camerei de joacă liberă și mișcare autonomă după Emmi Pikler.
</p>
<ToTopBtn></ToTopBtn>

        </div>
        </>
    )
}