import { Link } from "react-router-dom"
import List from "../../components/list/List"
import FindMore from "../../components/FindMore"
import { useState } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretRight } from "@fortawesome/free-solid-svg-icons";


import Image from "../../components/Image";

import nisipFoto1 from "../../images/servicii/camara_nisip/nisip_1.jpg"
import nisipFoto2 from "../../images/servicii/camara_nisip/nisp_2.jpg"
import ToTopBtn from "../../components/toTopBtn/toTopBtn";
export default function CamaraNisip(){


    const [isClicked,setIsClicked]=useState(false);
    const [isClicked_1,setIsClicked_1]=useState(false);
    const [isClicked_2,setIsClicked_2]=useState(false);
    const list_1=[
        
        "Spațiul prin explorare, descoperire, joacă, învățare liberă",
        "Își iau o pauză, când simt un impuls interior ca să o facă",
        "Materiale de joc potrivite pentru a încerca abilități motorii fine, precum și dispozitive de mișcare Pikler și Hengstenberg – pentru motorica grosieră",
        "Decid materialele, cu care vor să se joace, și dispozitivele pe care vor să le exploreze",
        "Cooperează cu semenii săi",
        "Siguranță, prin prezența și atenția părinților, sau a îngrijitorilor însoțitori",
        "Dezvoltă calitățile de socializare în comunitate",
        "Au posibilitatea de a lega o relație cu pedagoga KinderSpielRaum",
        "Sunt însoțiți în timpul conflictelor și ghidați de pedagogul",
    ]
    const list_2=[
        
        "au timp și liniște pentru observația cu plăcere a copilului său și descoperă astfel multe necesități ale pruncului său",
        "se bucură împreună unul lîngă altul și unul cu celălalt",
        "învață să fie un port sigur pentru copilul său fără să intervină în joaca lui",
        "consolidează relația părinte-copil prin înțelegere propriei inițiative a copilului",
        "însoțesc copiii spre autonomie și independență",
        "descoperă un comportament precaut și respectuos cu copiii",
        "susțin copiii cu o atenție drăgostoasă și neîmpărțită",
        "dezvoltă contacte și cunosc alți părinți",
        "au posibilitatea, spațiul și timpul pentru întrebări din cotidianul familiei în cadrul atelierelor de seară cu părinții",
    ]


    return(
        <div className="page-body-content">
            <h1>Cămara de joacă cu nisip</h1><h2>până la 6 ani</h2>
            <p></p>
            <p></p>
            <div className="row-img">

                    <div className="column">
                        Vă invităm în acest loc pentru mic și mare – sosiți, puteți să zăboviți, retrageți și să vă
            observați copilul adâncit în joaca cu nisipul.

                        <p>
                        Cămara de joacă cu nisip, este o inspirație de la pedagoga germană Ute Strub, la
            baza căreia stau principiile de educație ale Emmi Pikler, după care ne orientăm și în
            timpul Cursului părinte-copil.
                        </p>
                    </div>
                    <Image src={nisipFoto1} phoneWidth="80%" phoneMaxWidth="400px" tabletWidth="44%" desktopWidth="37%"></Image>

            </div>
            

           
            <p></p>
            <div className="row">
                <span className="accent-color-1">
                {isClicked_1?<FontAwesomeIcon icon={faCaretDown}></FontAwesomeIcon>:<FontAwesomeIcon icon={faCaretRight}></FontAwesomeIcon>}
                </span><h2>
                    <span style={{fontWeight:"bold"}} className="action-color-1" onClick={()=>{setIsClicked_1(!isClicked_1)}}>
                    De ce cămara cu nisip?
                </span>
                </h2>

            </div>
            
            {isClicked_1?<Why/>:""}
            
            <p></p>
            <div className="row">
                <span className="accent-color-1">
                {isClicked_2?<FontAwesomeIcon icon={faCaretDown}></FontAwesomeIcon>:<FontAwesomeIcon icon={faCaretRight}></FontAwesomeIcon>}
                </span><h2>
                    <span style={{fontWeight:"bold"}} className="action-color-1" onClick={()=>{setIsClicked_2(!isClicked_2)}}>
                        Vezi ofertele
                </span>
                </h2>

            </div>

            {isClicked_2?<Oferte/>:""}

            <h2>
                <p>
                    Condiții de rezervare
                </p>
            </h2>
            <p>            </p>

            Rezervarea se face în prealabil, contactându-ne telefonic sau lăsând un
mesaj la numărul de telefon.
            
            <FindMore text="Aici puteți citi condițiile." isClicked={isClicked} setIsClicked={setIsClicked}></FindMore>
            
            {isClicked?<Conditii/>:""}
            <p style={{fontWeight:"bold"}}>
            Prețul:
            </p>

            125 Euro pentru 3,5 h petrecute în Cămara de joacă cu nisip (pînă la 6 copii și
3 adulți), e inclusă pregătirea și curățenia după.



            <h2>
                <p>
                Copiii experimentează aici:
                </p>

            </h2>
            <p>            </p>

                <List lista={list_1}/>

            <h2>
                <p>
                Părinții experimentează aici:
                </p>

            </h2>
            <p>                </p>

                    <List lista={list_2}/>

            <p></p>
            <div className="row-img">
            <Image src={nisipFoto2} phoneWidth="80%" phoneMaxWidth="400px" tabletWidth="49%" desktopWidth="40.7%"></Image>

                    <div className="column">

                            Observarea atentă extinde înțelegerea propriului copil și sprijină dezvoltarea pașnică
                            și cu afecțiune a copilului. Pacea este cultivată și dusă din Cămara de joacă cu nisip,
                            în viața de familie.

                            <p>
                            Situațiile din timpul activității în grupurile din Cămara cu nisip, sau alte situații și
                            întrebări ale părinților, pot fi abordate în timpul Atelierelor serale organizate lunar,
                            pentru însoțitorii copiilor la cursuri.
                            </p>
                    </div>


                    </div>

                    <ToTopBtn></ToTopBtn>

        </div>
    )
}


function Conditii(){
    return(
        <>
        <p>
        <span style={{fontWeight:"bold"}}>
                Condiții:
            </span>
        </p>
        <p>
        Cel mai bine este mișcarea desculță în cămara de joacă, sau cu ciorapi cu o
talpă de piele. Rugăm fără încălțăminte cu talpă dură.
        </p>
        Pe loc găsiți apă plată, ceai, cafea, lapte gratuit.
        <p>
        Într-un colțișor , copiii pot lua gustărica adusă de acasă (fructe, legume sau
alte gustări).
        </p>
        <h3>Pentru Cămara de joacă rezervată, <span style={{fontWeight:"bold"}}>nu este o pedagogă însoțitoare</span>.</h3>
        Veți primi o informare pe loc despre ceea ce primiți.
        </>
    )
}

function Why(){
    return<>
              <p>
            Senzația de nisip fin, uscat pe piele, pe mâini, pe picioare este ceva cu totul special.
            </p>
    <p>
    Pielea noastră, ca cel mai mare organ senzorial, o percepe în atât de multe moduri.
Stimulii permanenți transmit creierului prin receptorii pielii, care sunt procesați ca
informații în creier și astfel stimulează dezvoltarea cognitivă și reducerea stresului.
Nisipul este un material minunat de tactil care poate fi folosit și pentru a explora în
mod jucăuș lumea fizică și științifică.
    </p>

    <p>
    Cămara este pregătită în funcție de vârsta copiilor înscriși. Veți găsi materiale de joc
potrivite pentru a încerca abilitățile motorii fine și diferite dispozitive de mișcare
adecvate (conform lui Pikler și Hengstenberg) pentru a încerca abilitățile motricii
grosiere. Pe lângă o varietate de ustensile de bucătărie, cel mai fin nisip este oferit
pentru joaca cu vasele de diferite dimensiuni. Pernele pe podea sunt așezate la
marginea camerei, pe care adulții însoțitori pot sta, observa și minuna.
    </p>
    </>
}


function Oferte(){
    return<>
        <p></p>
        <ul>
            <li>
                <span  className="color">
            Curs părinte-copil: Cămara de joacă cu nisip
de la 18 luni – 36 luni; 1 h 15 min (15 min sunt pauza la mijlocul ședinței)
</span></li>
<li>
                <span  className="color">
            Curs pentru copii Cămara de joacă cu nisip
de la 3 ani -6 ani (grupuri fixe, copiii pot fi însoțiți sau rămâne fără părinți), 1 h
30 (pauza pauza 15-20 minute)
</span></li>
            <li>
                <span  className="color">
            Zile de naștere- Cămara de joacă cu nisip
Vă doriți un alt program pentru ziua de naștere a copilului vostru?
</span></li>

            <li>
                <span  className="color">
            Timp cu familia și prietenii- Cămara de joacă cu nisip
            </span></li>
        </ul>
        Amenajăm Cămara cu nisip pentru 3,5 h și un spațiu pentru gustările aduse
de voi pentru copii. La sfîrșit, noi avem grijă să aducem sala în ordine.
    </>
}