import Image from "../../components/Image";
import List from "../../components/list/List";
import ToTopBtn from "../../components/toTopBtn/toTopBtn";
import foto from "../../images/servicii/cunoastere/1.jpg"

export default function CursCunoastereSine(){

    const lista=[
        "Încredere în propriile abilități",
        "Activitatea creativă",
        "Autoeficacitate",
        "Consolidarea încrederii de sine",
        "Socializarea",
        "Compasiunea, etc.",
    ]
    const lista_2=[
        "Copiii petrec în sala de curs 1 oră și 15 minute + 15 minute pauza de gustărică.",
        "În prima parte a ședinței, copiii se vor juca din propriul său impuls, și vor explora spațiul și dispozitivele Hengstenberg prin mișcări autonome: târât, așezat, mers, cățărat, lunecat, și oricare poziție confortabilă pentru sine. (45 minute)",
        "Pauza dintre prima și cea de a două parte de curs durează 15 minute, fiecare copil având la pachet o gustărică sănătoasă.",
        "În a doua parte a activității, pedagoga ghidează copiii în exprimarea propriilor trăiri din timpul mișcării autonome. Copiii dezvoltă vocabularul exprimării emoțiilor și propriilor nevoi, simțuri, limite, precum și dorințe. (30 minute)",
    ]

    const lista_3=[
        "Mergi desculță (picioarele sunt de la natură- organul de contact cu podeaua, cu pământul. Copiii au natural picioare vioaie, reactive, până pierd acest simț cu purtatul încălțămintei).",
        "Oferă-ți ție, și altora timp – este inima activității la acest curs. Fiecare copil, are dreptul să experimenteze, până este ”sătul”.",
        "Oferă spațiu și altora – completează regula 2.",
        "Acceptă-te pe tine, și pe ceilalți.",
        "Fă doar ceea, în ce ai încredere tu însuți.",
    ]

    return(
        <div className="page-body-content">
            <h1>Curs Cunoașterea de sine </h1>
            <h2> de la 3 ani până la 10 ani</h2>
            <p></p>

            <p>
            Acest Curs este despre cunoașterea și descoperirea sinelui prin joacă și mișcare autonomă. La baza
activității din acest curs, stau dispozitivele de mișcare – <a href="../principiile_pedagocice/Elfriede_Hengstenberg"> <span className="action-color-1">Hengstenberg</span></a>.
            </p>
            <p>
            Materialele de joacă și mișcare Hengstenberg, invită copiii spre găsirea echilibrului, precum și tratarea
autonomă a propriului corp. Pe parcursul săptămânilor, lunilor, copiii (re)câștigă competențele de bază:
            </p>
            <p>            </p>
                <div className="row-img" style={{justifyContent:"space-between"}}>
                                     
                    <div className="column">
                    <List lista={lista}/>
                    <h3 style={{marginTop:"1em"}}>
                    Fiecare copil, decide pentru sine, ce își dorește să exerseze, cât își încredințează, și primește suport,
atunci când simte că are nevoie. Copilul își ascultă corpul și motivația interioară, nefiind presat de cineva
din jur. Copiii din jur pot fi, prin exemplu, o motivație, de a-și depăși propriile granițe, prin curajul de a
încerca.
                    </h3>
                    </div>
                    <Image src={foto} phoneWidth="60%" phoneMaxWidth="250px" tabletWidth="41%" desktopWidth="41%"></Image>

                </div>
            <p>            </p>

                <List lista={lista_2}/>
            <h2>
            <p>
                Trei reguli de joacă de bază pentru copii:
            </p>
            </h2>
            <p>            </p>

                <List lista={lista_3}/>
            <p>
            *Primii 7-8 ani de mișcare și joacă autonomă sunt necesari pentru a transmite copilului competența
senzorial-motrică, care servește drept bază pentru dezvoltarea sa intelectuală, socială și personală.
Pedagoga germană Elfriede Hengstenberg s-a referit în lucrul ei cu copiii la fel, la competențele senzorial-
psihomotrice, făcînd referință la echilibrul interior și exterior. Acest Curs este o continuitate a educației
bazate pe principiile de educație ale Emmi Pikler.
            </p>
                <ToTopBtn></ToTopBtn>
        </div>
    )
}