import ToTopBtn from "../../components/toTopBtn/toTopBtn";


export default function MiniEduCasa(){
    return(
        <div className="page-body-content">
            <h1>Mini EduCasa</h1>
            <ToTopBtn></ToTopBtn>
        </div>
    )
}