
import { useState } from "react"
import FindMore from "../../components/FindMore"
import "./ActualStyle.css"
import YoutubeVideo from "../../components/YoutubeVideo"
import Image from "../../components/Image"
import foto_1 from "../../images/evenimente/eveniment_1.jpg"
import foto_2 from "../../images/evenimente/292028555_1455476241547193_2407890921254997199_n-724x1024.jpg"
import foto_3 from "../../images/evenimente/282994063_7369254523144768_1215566269337462479_n-1024x683.jpg"
import ToTopBtn from "../../components/toTopBtn/toTopBtn"

export default function Evenimente(){
    const [isClicked,setIsClicked]=useState(false)
    return(
        <div className="page-body-content">
            <h1>Evenimente</h1>
            <p></p>
            <p></p>
            <div className="actual_item">
                <h2>2 ani de activitate a KinderSpielRaum după Emmi Pikler</h2>
                <p>
                Seara se așternuse pe străzile Chișinăului. Rând pe rând, au venit părinții invitați la Atelierul seral de celebrare a doi ani de activitate a Centrului nostru. Fiecare din noi, am purtat o nuanță de alb și un zîmbet pe față, pentru a crea o atmosferă de magie și puritate.
                </p>

                <p>
                Lumina îngerașilor de ceară a invitat spre discuții de suflet, deschidere și încredere în cercul celor prezenți. Atâtea conștientizări, povești și situații au fost împărtășite de mamele prezente.
                </p>

                <p>
                Creăm acest spațiu de siguranță pentru părinți și actori educaționali de 2 ani, pentru că interacțiunea emoțională armonioasă a adulților îngrijitori contribuie nemijlocit la sănătatea minții lor și a celei psiho-emoționale. Părinții liniștiți cresc copii fericiți!
                <span className="accent-color-3"> ♡</span>
                </p>

                <p>
                Aici puteți simți atmosfera Atelierului.
                </p>
                <p></p>
                <YoutubeVideo src="https://www.youtube.com/embed/7ZiCis-wHio?si=1q9pQMiOy6B17TB9" name="Youtube Video"></YoutubeVideo>
            </div>
            <p></p>
            <div className="actual_item">
                <h2>IA MANIA Festival 2022</h2>
                <p style={{fontWeight:"bold"}}>17 iunie  2022</p>
                <p></p>
                <div className="row-img">
                
                    <Image src={foto_1} phoneWidth="60%" phoneMaxWidth="250px" tabletWidth="30%" desktopWidth="32%"></Image>

                    <div className="column">
                            În această vară, ne-am dorit să fim și în aer liber. În cooperare cu KLUMEA am putut organiza un spațiu de joacă minunat pentru copiii de vîrsta 6 luni-36 luni la IA MANIA în Holercani! Părinți conștienți și copii fericiți s-au bucurat de spațiul nostru amenajat cu multă iubire.
                        A fost superb!
                        <p>
                        Aici puteți accesa un reportaj creat de echipa Telematinal, de la TVR Moldova: <a className="action-color-1" href="https://www.facebook.com/watch/?v=719716439332956" >Reportaj TVR</a>
                        </p>
                    </div>
                </div>
                

            </div>
            <p></p>
            <div className="actual_item">
                <h2>Bursa de studii pentru pedagoga noastră
                </h2>
                <p style={{fontWeight:"bold"}}>
                07 iunie  2022
                </p>
                <p></p>

                <div className="row-img">
                    <Image src={foto_2} phoneWidth="60%" phoneMaxWidth="250px" tabletWidth="30%" desktopWidth="32%"></Image>

                    <div className="column">
                În urma unui concurs internațional organizat de Pikler International , fondatoarea și pedagoga <span className="accent-color-1"> KinderSpielRaum după Emmi Pikler</span>, Ana Bordos -Boldisor, a cîștigat bursa de studii pentru cursurile de instruiri specializate, organizate de Pikler Institut din Budapesta și titlul de Membră Pikler International!
                <p>
                Ne bucurăm să fim parte oficial a Comunității PIKLER INTERNATIONAL!
                </p>
                    </div>

                </div>

               
            </div>
            <p></p>
            <div className="actual_item">
                <h2>
                Curs de inițiere în Pedagogia Pikler pentru actorii educaționali ai Misiunii Sociale ”Diaconia”
                </h2>
                <p style={{fontWeight:"bold"}}>23 mai 2022

                </p>
                <p></p>
                <div className="row-img">
                    <Image src={foto_3} phoneWidth="80%" phoneMaxWidth="400px" tabletWidth="33%" desktopWidth="32%"></Image>

                    <div className="column">
                    În perioada 16 mai – 21 mai, pedagogele noastre, Ana Bordos-Boldișor și Alla Ceban ,
instruite și delegate de către Institutul Pikler, au organizat un Curs de inițiere în
Pedagogia Pikler pentru actorii educaționali ai Misiunii Sociale „Diaconia”.  Ne bucurăm de deschiderea partenerilor noștri pentru dorința lor de a implementa acest concept în activitatea lor socio-educațională.
              
                    </div>
                </div>

               <p>
                </p>
                <FindMore text="vezi mai mult" isClicked={isClicked} setIsClicked={setIsClicked}>
                    </FindMore>

                {isClicked?<More/>:""}

            </div>
            <ToTopBtn></ToTopBtn>
        </div>
    )
}


function More(){
    return(
        <>
            <p>
            În timpul zilelor de training, promovăm cu multă responsabilitate:
            </p>

            <p>
            Cu mare deschidere am împărtășit experiențele noastre cu acești actori educaționali plini
de deschidere și mărinimie! Primii trei ani de viață sunt esențiali în educarea copilului.
Copiii voștri au dreptul să fie îngrijiți și educați de oameni plini de iubire!🙏
            </p>

            <p>
            Aici puteți accesa reportajul TVR: <a className="action-color-1" href="https://tvrmoldova.md/article/bfa520433371b8e5/misiunea-sociala-diaconia-lanseaza-o-cresa-ce-va-functiona-dupa-un-program-educational-special.html?fbclid=IwAR3Nsuqr6dwnJDphfyMpKf4c9aLmxlMXlG5ChAKtiX3gCdCV6g6amYDJaws#.YovfqjonyAU">Reportaj TVR</a>
            </p>
        </>
    )
}