import './App.css';
import ErrorPage from "./ErrorPage.js";
import {Route,Routes} from "react-router-dom";
import { Layout } from './components/navigation/Layout';
import Despre from './pages/EduCasa/Despre';
import Contacte from './pages/Contacte/Contacte';
import EmmiPikler from './pages/PrincipiiPedagocice/EmmiPikler';
import Elfried from './pages/PrincipiiPedagocice/Elfried';
import Ute from './pages/PrincipiiPedagocice/Ute';
import Istoria from './pages/EduCasa/Istoria';
import Acasa from './pages/EduCasa/Acasa';
import AteliereSerale from './pages/Servicii/AteliereSerale';
import CursBlandete from './pages/Servicii/CursBlandete';
import CursParinteCopil from './pages/Servicii/CursPărinteCopil';
import CursCunoastereSine from './pages/Servicii/CursCunoastereSine';
import CamaraNisip from './pages/Servicii/CamaraNisip';
import Consiliere from './pages/Servicii/Consiliere';
import MiniEduCasa from './pages/Servicii/MiniEduCasa';
import Link from './pages/Actual/Link';
import Literatura from './pages/Actual/Literatura';
import AnaBordos from './pages/DespreNoi/AnaBordos';
import AllaCeban from './pages/DespreNoi/AllaCeban';
import FormularInscriere from './pages/EduCasa/FormularInscriere';
import Noutati from './pages/Actual/Noutati';
import Evenimente from './pages/Actual/Evenimente';
import Presa from './pages/Actual/Presa';
import FormContact from './pages/Contacte/FormContact';


function App() {
  return (
        <Routes>
          <Route element={<Layout/>}>
            <Route exact path="/" element={<Acasa/>}/>
            <Route path="/principiile_pedagocice/Emmi_Pikler" element={<EmmiPikler/>}/>
            <Route path="/principiile_pedagocice/Elfriede_Hengstenberg" element={<Elfried/>}/>
            <Route path="/principiile_pedagocice/Ute_Strub" element={<Ute/>}/>
            <Route path="/misiune_și_valori" element={<Despre/>}/>
            <Route path='/istoria' element={<Istoria/>}/>
            <Route path='/servicii/ateliere_serale' element={<AteliereSerale/>}/>
            <Route path="/servicii/curs_de_educație_cu_blândețe" element={<CursBlandete/>}/>
            <Route path="/servicii/curs_părinte-copil" element={<CursParinteCopil/>}/>
            <Route path="/servicii/curs_de_cunoaștere_de_sine" element={<CursCunoastereSine/>} />
            <Route path="/servicii/cămara_de_joacă_cu_nisip" element={<CamaraNisip/>}/>
            <Route path="/servicii/consiliere_individuală" element={<Consiliere/>}/>
            <Route path="/servicii/mini-educasa" element={<MiniEduCasa/>}/>
            <Route path="/actual/linkuri" element={<Link/>}/>
            <Route path="/actual/literatură" element={<Literatura/>}/>
            <Route path='/Ana_Bordos_Boldișor' element={<AnaBordos/>}/>
            <Route path='/Alla_Ceban' element={<AllaCeban/>}/>
            <Route path="/înscrie-te" element={<FormularInscriere/>}/>
            <Route path="/noutăți_oferte" element={<Noutati/>}/>
            <Route path="/actual/evenimente" element={<Evenimente/>}/>
            <Route path="/actual/presa" element={<Presa/>}/>
            <Route path="/formular_de_contact" element={<FormContact/>}/>

            <Route path="/contacte" element={<Contacte/>}/>
          </Route>
        </Routes>
  );
}

export default App;
