
import React from "react"
import { useEffect,useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./topBtnStyle.css";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
export default function ToTopBtn(){
    const [toTopBtn,setToTopBtn]=useState(false);

    useEffect(()=>{
            window.addEventListener("scroll",()=>{
                if(window.scrollY>200){
                    setToTopBtn(true)
                }else{
                    setToTopBtn(false)
                }
            })
    })

    const scrollUp=()=>{
        window.scroll({ top: 0,left: 0, behavior: "smooth",})
    }
    return(
        <>
            {toTopBtn&&(
                <button className="topBtn" onClick={scrollUp}><FontAwesomeIcon icon={faArrowUp} size="xl"></FontAwesomeIcon></button>
            )}
        </>
    )
}