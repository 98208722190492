import { Link } from "react-router-dom";
import {useState} from 'react';
import "./DropDownStyle.css"

export default function DropDown({items,setisDropDownActive,setShowSubMenu,setIsDrawerOpen}){
    const [dropDown,setDropDown]=useState(false);

    return(
        <>
        <div className={dropDown?"subMenu-container clicked":"subMenu-container nav-bar-color"}>
            <ul onClick={()=>{setDropDown(!dropDown);}}>
                { 
                    items&&items.map((item,index)=>{
                        return(
                            <li key={index}>
                                <Link to={item.path}  className="item"
                                onClick={()=>{window.scroll({ top: 0,left: 0, behavior: "auto",});setDropDown(false);setisDropDownActive(false);setShowSubMenu(0,false); setIsDrawerOpen(false)}}>
                                    {item.name}
                                </Link>
                            </li>
                        )
                    }) 
                }
            </ul>
            </div>
        </>
    )
}