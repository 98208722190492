import List from "../../components/list/List"
import ToTopBtn from "../../components/toTopBtn/toTopBtn"
import "../../components/ButtonStyle.css";

export default function CursBlandete(){

    const lista=[
        "Etapele de dezvoltare ale copilului în primii ani de viață",
        "Rolul observației în educație și îngrijire. Nevoile copilului și ale părinților",
        "Îngrijirea cu afecțiune, spre dezvoltarea unei relații armonioase părinte-copil",
        "Mișcarea autonomă și impactul ei asupra dezvoltării psiho-emoționale a copilului",
        "Importanța jocului liber. Interesele de joacă ale copiilor",
        "Dezvoltarea limbajului și comunicarea cu copilul",
        "Primii pași spre socializare: provocări",
        "Situațiile de conflict între copii",

    ]
    return(
        <>
            <div className="page-body-content">
                <h1>Curs de educație cu blândețe</h1>
                <h2> pentru părinți și viitori părinți</h2>
                <p></p>

                
                <h2>
                    <p>
                        Despre ce este acest curs?
                    </p>
                </h2>
                <p>                </p>

                    <List lista={lista}/>
                <p>
                Dragi părinți și viitori părinți, activitatea noastră în EduCasa, precum și cercetările
privind rolul educației timpurii în viața umană, ne confirmă importanța pregătirii
părinților pentru un viitor pașnic și sănătos al adulților de mâine.
                </p>
                <p>
                <span className="accent-color-1" style={{fontWeight:"bold"}}>Scopul</span> acestui curs este de a veni în întâmpinarea nevoilor multor părinți, cu o
susținere și însoțire competentă pentru educarea și îngrijirea copiilor lor cu multă
afecțiune și iubire.
                </p>
                <p>
                Acest curs conține informații teoretice, exerciții practice de compasiune și conectare,
video cu exemple practice.
                </p>
                <div className="linie">
                    <span style={{fontWeight:"bold"}}>Pentru înscriere la "Cursul de parenting cu blândețe" accesează următorul link:</span>
                    <a className="btn-1" href=" https://rb.gy/eyb4w1" target="_blank">Înscrie-te</a>
                </div>
                <ToTopBtn></ToTopBtn>
            </div>
        </>
    )
}