import "./PrincipiiPedagociceStyle.css"
import { useState } from "react";
import { faPlus,faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import EmmiPiklerFoto from "../../images/Emmi-Pikler.jpg"
import ToTopBtn from "../../components/toTopBtn/toTopBtn";

export default function EmmiPikler(){
    const [currPilon,setCurrPilon]=useState(0);

    const piloni=[{
        index:0,
        name:"Îngrijirea bazată pe relație", 
        descrition:"În acțiunile de îngrijire cotidiană, precum mîncarea, înfășarea, îmbrăcarea și dezbrăcarea, spălarea mîinilor, ș.a. se oferă ocazii speciale fiecărui copil de a i se dărui afecțiune drăgostoasă și atenție. Dacă mîinile sunt „delicate, sensibile, prudente, pipăitoare“, atunci copilul se relaxează, conform observațiilor Pikler, și se simte iubit. Copilul trăiește: „Eu sunt luat în serios, nimic nu se întîmplă dincolo de mine.“", 
        motou:"„Doar dacă copilul se simte sigur și protejat și are experiența să fie iubit, este capabil să acționeze autonom și să încerce ceva nou.“"},
    {
        index:1,
        name:"Dezvoltarea autonomă a mișcării",
        descrition:"Dezvoltarea liberă a mișcării înseamnă pentru noi adulții responsabili și însoțitori ai copilului, să pregătim un spațiu/mediu provocator, însă nu suprasolicitat, unde copilul însuși pune la încercare și descoperă competențe noi de mișcare. Așa numitele „Dispozitive de mișcare Pikler“, precum sunt Lădița de-a bușilea, Labirintul, sau Stativul triunghiular, dar și pernele, saltelele stimulează siguranța în propriile mișcări, independența, autoaprecierea, încrederea în sine, dorința pentru descoperire.",
        motou:"„Dacă avem încredere în capacitățile și activitățile autonome ale copilului, și creăm un mediu ambiant susținător pentru propriile sale încercări de învățare, copilul este mult mai capabil decât să fie în general acceptat.“"},
    {
        index:2,
        name:"Joaca liberă",
        descrition:"Pruncul poate să se decidă foarte timpuriu pentru o ocupație  și satisfăcut să fie activ „creativ“. Pentru aceasta are nevoie în primul rînd  de timp, liniște și materialele/jucăriile corespunzătoare.",
        motou:"„Este esențial ca copilul să descopere singur pe cît posibil mai multe lucruri. Dacă noi îi suntem copilului de ajutor la soluționarea sarcinilor, astfel îl privăm tocmai de ceea ce este cel mai important pentru dezvoltarea sa.  Un copil, care atinge ceva prin experimentele proprii, dobîndește total o altă cunoaștere decât cel, căruia i se oferă soluția pregătită.“",
    }
]
    return<>
        <div className="page-body-content">
                <div className="header">
                    <div className="foto" style={{backgroundImage:`url(${EmmiPiklerFoto})`,borderRadius:"0.5em"}}></div>
                    <div className="column">
                        <h1>Emmi Pikler <span style={{fontWeight: "normal"}}>(Viena, 1902 – Budapesta, 1984)</span></h1>
                        <p>
                        Emmi Pikler născută în Viena (1902), cu studiile de specialitate finisate în Austria, 
                        pediatră în Ungaria, fondatoarea și directoarea de lungă durată a Institutului Pikler
                        „Loczy“ în Budapesta, Ungaria.  Astăzi casa adăpostește o creșă, care se orientează
                        după pedagogia ei și care oferă spații de joacă (SpielRaum). Preocuparea Emmi
                        Pikler a fost să se permit copiilor o evoluție pe cît posibil nestingherită; ea a realizat
                        cercetări numeroase pe această temă.
                        </p>
                    </div>
                    
                </div>
                    <h2><p>Pedagogia Emmi Pikler</p></h2>
                    <p>
                    Atitudinea fundamentală în pedagogia Emmi Pikler este tratarea copilului cu
seriozitate chiar de la naștere, ca om și partener, și dezvoltarea sănătoasă a copilului
cu încredere – conform propriilor sale dimensiuni ale timpului.
                    </p>
                        <h2>                    <p>Cei trei piloni ai Pedagogiei Pikler sunt:</p></h2>
                        {
                            piloni.map((element) => {
                                if(currPilon===element.index){
                                    return(
                                            <h3 key={element.index}>
                                                <div className="piloni-title accent-color-1" key={element.index}>
                                                    <FontAwesomeIcon icon={faMinus} size="xs"></FontAwesomeIcon>
                                                    <p style={{fontWeight:"bold"}}>{element.name}</p>
                                                </div>
                                                <p>{element.descrition}</p>
                                                <p style={{fontStyle:"italic"}}>{element.motou}</p>
                                                <p style={{textAlign:"center"}}> - Emmi Pikler -</p>
                                            </h3> 
                                    )
                                }else{
                                    return(
                                            <h3 key={element.index}>
                                                <div className="piloni-title action-color-5" key={element.index} onClick={()=>setCurrPilon(element.index)}>
                                                    <FontAwesomeIcon icon={faPlus} size="xs"></FontAwesomeIcon>
                                                    <p style={{fontWeight:"bold"}}>{element.name}</p> 
                                                </div>                                               
                                            </h3>
                                    )
                                }
                            
                            })
                        }
                        <ToTopBtn></ToTopBtn>
        </div>
    </>
}