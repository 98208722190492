import List from "../../components/list/List"
import Image from "../../components/Image"
import foto_1 from "../../images/servicii/parinte_copil/kinder_1.jpg"
import foto_2 from "../../images/servicii/parinte_copil/kinder_2.jpg"
import foto_3 from "../../images/servicii/parinte_copil/kinder_3.jpg"
import { useState,useLayoutEffect } from "react"
import ToTopBtn from "../../components/toTopBtn/toTopBtn"

export default function CursParinteCopil(){
    const [mobile, setMobile] = useState(false);

    const parintii_lista=[
        "au timp și liniște pentru observația cu plăcere a copilului său și descoperă astfel multe necesități ale pruncului său",
        "se bucură împreună unul lîngă altul și unul cu celălalt",
        "învață să fie un port sigur pentru copilul său fără să intervină în joaca lui",
        "consolidează relația părinte-copil prin înțelegere propriei inițiative a copilului",
        "însoțesc copiii spre autonomie și independență",
        "descoperă un comportament precaut și respectuos cu copiii",
        "susțin copiii cu o atenție drăgostoasă și neîmpărțită",
        "dezvoltă contacte și cunosc alți părinți",
        "au posibilitatea, spațiul și timpul pentru întrebări din cotidianul familiei în cadrul atelierelor de seară cu părinții",
    ]

    const copii_lista=[
        "decid independent, ce doresc cu drag să exploreze​",
        "dezvoltă inițiative proprii, diverse preferințe, personalități individuale",
        "întâlnesc alți copii de aceleiași vârstă, descoperă diverse strategii de soluționare a dificultăților și conflictelor",
        "își gestionează frustrările lor",
        "trăiesc experiențe valoroase pentru propria dezvoltare",
        "primesc siguranță prin prezența și atenția părinților în spate",
        "dezvoltă calitățile de socializare în comunitate",
        "au posibilitatea de a lega o relație cu pedagoga KinderSpielRaum",
        "sunt însoțiți în timpul conflictelor",
        "savurează timpul lor în timpul jocului liber"
    ]

    useLayoutEffect(() => {
        setMobile(window.matchMedia("(max-width: 770px)").matches);
        window.addEventListener('resize', () => setMobile((window.matchMedia("(max-width: 770px)").matches)));
        return () => window.removeEventListener('resize', () => setMobile((window.matchMedia("(max-width: 770px)").matches)));
    }, []);



    return(
        <>
            <div className="page-body-content">
                <h1>KinderSpielRaum: Curs părinte-copil</h1>
                <h2> de la 6 luni pînă la 36 luni</h2>
                <p></p>
                <p></p>
                
                <Header mobile={mobile}/>
                <p>
                Maxim 8 copii formează o grupă omogenă de vârstă. Materialele diverse, precum
sunt: coșuri, mingi, batiste, inele, și dispozitivele de mișcare, precum sunt: suportul
triunghiular, labirint, lada pentru târât, taburete, stau la dispoziție conform vârstei
copiilor. Materialele și aparatele Pikler invită spre încercare. Copiii decid, cu ce și cât
timp se vor juca.
                </p>


                <h2>
                    <p>
                        Copiii experimentează aici:
                    </p>
                    </h2>
                <p></p>
                <div className="row-img">
                    <List lista={copii_lista}/>
                    <Image src={foto_2} phoneWidth="60%" phoneMaxWidth="250px" tabletWidth="40%" desktopWidth="35%"></Image>

                    </div>

                <p>
                Copiii nu sunt îndrumați și invitați să facă anumite activități. Ei fac, ceea ce își doresc
de la sine să facă, și pentru care sunt deja pregătiți corespunzător dezvoltării sale. Pe
perioada întregului timp petrecut în KinderSpielRaum, copiii sunt însoțiți de noi,
Pedagogele Pikler, precaute, respectuoase și total prezente.
                </p>

                <h2>
                    <p>
                        Părinții experimentează aici:
                    </p>
                </h2>
                <p>                </p>

                <MobileVers mobile={mobile} parintii_lista={parintii_lista}/>

                <p>
                Pentru că în timpul cursului din<span className="accent-color-1"> KinderSpielRaum </span>atenția este direcționată copiilor,
discuțiile cu părinții au loc în cadrul atelierelor, organizate seral. Acestea oferă
posibilitatea  de post-discuții și spațiu de a adresa întrebări, precum și din viața
cotidiană a părinților.
                </p>

                <ToTopBtn></ToTopBtn>
            </div>
        </>
    )
}



function MobileVers({mobile,parintii_lista}){

    if(mobile){
        return(
            <>
                <List lista={parintii_lista}/>
                <p></p>
                <Image src={foto_3} phoneWidth="60%" phoneMaxWidth="250px" tabletWidth="42%" desktopWidth="40%"></Image>

            </>
        )
    }else{
        return(<>
         <div className="row-img-1">
                <Image src={foto_3} phoneWidth="60%" phoneMaxWidth="250px" tabletWidth="42%" desktopWidth="38%"></Image>

                <List lista={parintii_lista}/>


        </div>
        </>)
    }
}

function Header({mobile}){

    if(mobile){
        return(
            <>
               <div className="row-img">

                        <h3>
                    <span className="accent-color-1">KinderSpielRaum după Emmi Pikler</span> este un spațiu pentru <span className="accent-color-1">Curs-Părinte-Copil</span>, care
                            se orientează pe conceptul pedagogic al pediatrei ungare Emmi Pikler asupra
                            educației timpurii a copilului și dezvoltării părintelui.<br/>
                            Oferim cursul pentru copiii cu vârsta cuprinsă între 3 luni și 36 luni. Aici copiii își
                            găsesc un mediu pregătit corespunzător vârstelor sale, în care își pot satisface
                            interesele  de mișcare autonomă, joacă liberă și liniște.
                            </h3>
                    <Image src={foto_1} phoneWidth="80%" phoneMaxWidth="400px" tabletWidth="42%" desktopWidth="45%"></Image>
            </div>
            </>
        )
    }else{
        return(<>
         <div className="row-img">
                <Image src={foto_1} phoneWidth="80%" phoneMaxWidth="400px" tabletWidth="42%" desktopWidth="45%"></Image>

                    <div className="column">
                        <h3>
                    <span className="accent-color-1">KinderSpielRaum după Emmi Pikler</span> este un spațiu pentru <span className="accent-color-1">Curs-Părinte-Copil</span>, care
                            se orientează pe conceptul pedagogic al pediatrei ungare Emmi Pikler asupra
                            educației timpurii a copilului și dezvoltării părintelui.<br/>
                            Oferim cursul pentru copiii cu vârsta cuprinsă între 3 luni și 36 luni. Aici copiii își
                            găsesc un mediu pregătit corespunzător vârstelor sale, în care își pot satisface
                            interesele  de mișcare autonomă, joacă liberă și liniște.
                            </h3>
                    </div>
            </div>

        </>)
    }
}