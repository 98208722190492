import Image from "../../components/Image";
import ToTopBtn from "../../components/toTopBtn/toTopBtn";
import jucarii from "../../images/noutati/jucarii.jpg"
import "./ActualStyle.css"



export default function Noutati(){

    return(
        <div className="page-body-content">
            <h1>Noutăți și Oferte</h1>
            <p></p>
            <p> </p>               

            <div className="actual_item">
                <div className="row-img">

                    <div className="column">
                        Dragi părinți, dăm startul înscrierilor pentru cursul părinte-copil din cadrul KinderSpielRaum după Emmi Pikler pentru anul 2023.
                        <p>
                        Rezervarea locului se face prin completarea și transmiterea în scris a formularului.
                        <span className="action-color-1"><a href="../înscrie-te">(Formular de înscriere)</a></span>
                        </p>

                        <p>
                        Ținteți cont de fapul că grupele sunt de maxim 6 copii cu părintele însoțitor, locurile sunt limitate.
                        </p>
                        Vă așteptăm cu mult drag!

                    </div>

                    <Image src={jucarii} phoneWidth="80%" phoneMaxWidth="400px" tabletWidth="40%" desktopWidth="44%"></Image>

                   
                </div>
            </div>
            <ToTopBtn></ToTopBtn>

        </div>
    )
}