import "./ServiciiStyle.css"
import List from "../../components/list/List"
import Image from "../../components/Image"
import atelierFoto1 from "../../images/servicii/atelier_parinti/atelier_1.jpg"
import atelierFoto2 from "../../images/servicii/atelier_parinti/atelier_2.jpg"
import { useState,useLayoutEffect } from "react";
import ToTopBtn from "../../components/toTopBtn/toTopBtn"

export default function AteliereSerale(){

    const lista=[
        "viziuni variate",
        "posibilitatea de a învăța unul/una de la celălalt/cealaltă",
        "suport pentru ceea „ce e potrivit mie și familiei mele”",
        "cuvinte, ce oferă siguranță copilului",
        "calea relației între părinte și copil",
        "răspunsuri pentru întrebări neadresate"
    ]

        return(
            <>
                <div className="page-body-content">
                    <h1>Ateliere serale pentru părinți</h1>
                    <p></p>
                    <p></p>
                    
                    <Header/>
                    <p>
                    Atelierele serale pentru părinți sunt sursa  de încărcare a mamei sau tatălui,
                    este locul acceptării, siguranței, curajului și autenticității. 
                    </p>
                    <h2><p>Ce oferă:</p></h2>
                    <p></p>
                            <List lista={lista}/>
                        
                    <p>
                            Punctul de plecare ale acestor ateliere sunt temele Voastre.
                    </p>
                    <h2><p>Timp:</p></h2>
                    <p>
                        17.30 – 20.00
                    </p>
                    <p>
                    </p>
                    <div className="row-img">
                        <div className="column">
                        Mamele și tații stau astăzi față în față cu multe provocări. Activitatea zilnică
    cu copiii necesită implicarea imensă a adulților. Ei trebuie să organizeze rutina
    zilnică: pregătirea mîncării, spălare rufe, planificarea timpului liber; să
    recunoască interesele copiilor și să își gestioneze emoțiile lor, cît și a copiilor
    săi. Uneltele din bagaj (propriile experiențe din copilărie) deseori nu sunt
    îndeajuns pentru a implementa viziunile ideale.
                        </div>
                        <Image src={atelierFoto2} phoneWidth="80%" phoneMaxWidth="400px" tabletWidth="47%" desktopWidth="45%"></Image>

                    </div>
                    <p></p>
           
                    <p>
                    Trăim în această lume a schimbărilor. Copiii nu mai reacționează la presiunile
    și amenințările părinților. Ei nu mai vor să respecte regulile. Se consideră
    deseori maturi, de a acționa, astfel cum își doresc. Părinții ajung la limita
    cunoașterii. Dar totuși, e posibil să se creeze o atmosferă plăcută în familie.
                    </p>
                    <p>
                    “Cum?” aflați în timpul Atelierelor pentru părinți
                    </p>
                    <ToTopBtn></ToTopBtn>
                </div>
            </>
        )
   
}


function Header(){
    const [mobile, setMobile] = useState(false);

    useLayoutEffect(() => {
        setMobile(window.matchMedia("(max-width: 600px)").matches);
        window.addEventListener('resize', () => setMobile((window.matchMedia("(max-width: 600px)").matches)));
        return () => window.removeEventListener('resize', () => setMobile((window.matchMedia("(max-width: 600px)").matches)));
    }, []);

    if(mobile){
        return(
            <>
                 <h3 style={{fontStyle:"italic", fontWeight:"bold", textAlign:"right"}}>
                            Iubește-mă,
                            <p>cînd merit cel mai puțin</p>
                            <p>pentru că în aceste momente,</p>
                            <p>am nevoie cel mai mult de Dragostea ta.</p>
                            </h3>
                        <p></p>
                    <Image src={atelierFoto1} phoneWidth="60%" phoneMaxWidth="250px" tabletWidth="30%" desktopWidth="30%"></Image>

            </>
        )
    }else{
        return(
            <>
            <div className="row" style={{justifyContent:"space-between"}}>
                        
                        <Image src={atelierFoto1} phoneWidth="30%" phoneMaxWidth="30%" tabletWidth="30%" desktopWidth="30%"></Image>
    
                        <div className="column" style={{alignSelf:"center"}}>
                            <h3 style={{fontStyle:"italic", fontWeight:"bold", textAlign:"right"}}>
                            Iubește-mă,
                            <p>cînd merit cel mai puțin</p>
                            <p>pentru că în aceste momente,</p>
                            <p>am nevoie cel mai mult de Dragostea ta.</p>
                            </h3>
                        </div>
                    </div>
            </>
        )
    }


}