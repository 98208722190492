import DatePicker from "react-datepicker";
import { useState,useEffect } from "react";
import "./EduCasaStyle.css"
import "react-datepicker/dist/react-datepicker.css"; 

import { useFormik } from "formik";

import * as Yup from "yup";
import "../../components/ButtonStyle.css";
import "../../components/FormStyle.css";
import React from "react";
import { useRef } from "react";
import loading from "../../images/Spinner-1.6s-200px.gif"
import emailjs from '@emailjs/browser';

import success from "../../images/check-mark.png"
import ToTopBtn from "../../components/toTopBtn/toTopBtn";

const sleep = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

export default function FormularInscriere(){
    const [isSubmit,setIsSubmit]=useState(false);

    const [submitBtn,setSubmitBtn]=useState(false);

    // function goToTop(){ useEffect(()=>{
    //   })}

    const formik= useFormik({
        initialValues:{
            nume_par:'',
            prenume_par:'',
            nume_cop:'',
            prenume_cop:"",
            data_cop:"",
            data_nastere:"",
            serviciu:"KinderSpielRaum: Curs părinte-copil",
            email:"",
            telefon:"",
            info:"",

        },

        //validate form
        
        validationSchema:Yup.object({
            nume_par:Yup.string().max(20,"Maxim 20 caractere.").required("Acest câmp este obligatoriu."),
            prenume_par:Yup.string().max(20,"Maxim 20 caractere.").required("Acest câmp este obligatoriu."),
            nume_cop:Yup.string().max(20,"Maxim 20 caractere.").required("Acest câmp este obligatoriu."),
            prenume_cop:Yup.string().max(20,"Maxim 20 caractere.").required("Acest câmp este obligatoriu."),
            data_cop:Yup.date().required("Acest câmp este obligatoriu."),
            email: Yup.string().email("Adresă de email invalidă").required("Acest câmp este obligatoriu."),
            telefon: Yup.string().matches(/^\+?[0-9]+$/,"Număr de telefon invalid.").required("Acest câmp este obligatoriu."),
        }),

        onSubmit:async (values)=>{

            setSubmitBtn(true);

           values.data_nastere = `${
                values.data_cop.getDate()
              }/${values.data_cop.getMonth() + 1}/${values.data_cop.getFullYear()}`;
              


            await emailjs.send('service_7ru5o25', 'template_54cdxru', 
                values, 'aX8I-MQ3QFijUGNbq')
                .then(() => {
              console.log('email sent');
            });

            setIsSubmit(true);

            window.scrollTo({top:0,left:0,behavior:"smooth"});

            // await sleep(10000)
            // setIsSubmit(false)
            formik.resetForm()
            setSubmitBtn(false)
      
        }
    })

    if(isSubmit===false){
        return(
            <div className="page-body-content">
                <h1>Formular de înscriere</h1>
                <p></p>

                <form onSubmit={formik.handleSubmit}>
                <div className="formular_component">
                        Nume/Prenume Părinte
                    <div className="linie">
                        <div className="col">
                        <input 
                        type="text" 
                        required
                        name="nume_par" 
                        placeholder="Nume"
                        value={formik.values.nume_par}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.nume_par && formik.errors.nume_par ? <span className="err">{formik.errors.nume_par}</span>:''}
                        </div>
                    
                            
                    <div className="col">
                    <input
                     type="text" 
                     required
                     name="prenume_par" 
                     placeholder="Prenume"
                     value={formik.values.prenume_par}
                     onChange={formik.handleChange}
                     onBlur={formik.handleBlur}
                     ></input>
                    
                    {formik.touched.prenume_par && formik.errors.prenume_par ? <span className="err">{formik.errors.prenume_par}</span>:''}
    
                    </div>
    
                    </div>
                </div>
    
                <div className="formular_component">
                        Nume/Prenume Copil
                    <div className="linie">
                    <div className="col">
                    <input 
                    type="text" 
                    required
                    name="nume_cop" 
                    placeholder="Nume"
                    value={formik.values.nume_cop}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    ></input>
                    {formik.touched.nume_cop && formik.errors.nume_cop ? <span className="err">{formik.errors.nume_cop}</span>:''}
    
                    </div>
                    <div className="col">
                    <input 
                    type="text" 
                    required
                    name="prenume_cop" 
                    placeholder="Prenume"
                    value={formik.values.prenume_cop}
                     onChange={formik.handleChange}
                     onBlur={formik.handleBlur}
    
                    ></input>
                        {formik.touched.prenume_cop && formik.errors.prenume_cop ? <span className="err">{formik.errors.prenume_cop}</span>:''}
    
                    </div>
                    
    
                    </div>
                </div>
                <div className="formular_component">
                    <label>
                        Data nașterii copilului
                    </label>
                    <DatePicker maxDate={new Date()} minDate={new Date("01-09-2010")} yearDropdownItemNumber={10} onFocus={(e) => e.target.readOnly = true} required name="data_cop" showYearDropdown adjustDateOnChange placeholderText="Selectează data" dateFormat={"dd/MM/yyyy"} selected={formik.values.data_cop} 
                                     onBlur={formik.handleBlur}
                     onChange={(val)=>formik.setFieldValue('data_cop',val)} />
                    {formik.touched.data_cop && formik.errors.data_cop ? <span className="err">{formik.errors.data_cop}</span>:''}
    
                </div>
    
                <div className="formular_component">
                    <label htmlFor="serviciu">
                        Serviciul
                    </label>
                    <select required name="serviciu" value={formik.values.serviciu} onChange={formik.handleChange}>
                        <option>KinderSpielRaum: Curs părinte-copil</option>
                        <option>Cămara de joacă cu nisip</option>
                        <option>Curs cunoașterea de sine</option>
                        <option>Curs de educație cu blândețe</option>
                        <option>Ateliere serale pentru părinți</option>
                        <option>Consiliere individuală</option>
                    </select>
                </div>
    
                <div className="formular_component">
                    <label htmlFor="email">
                        Email
                    </label>
                    <input required type="text" name="email" placeholder="example@example.com" value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur}></input>
                    {formik.touched.email && formik.errors.email ? <span className="err">{formik.errors.email}</span>:''}
    
    
                </div>
    
                <div className="formular_component">
                    <label htmlFor="telefon">
                        Număr de contact
                    </label>
                    <input required type="tel" name="telefon" placeholder="+373" value={formik.values.telefon} onChange={formik.handleChange} onBlur={formik.handleBlur}></input>
                    {formik.touched.telefon && formik.errors.telefon ? <span className="err">{formik.errors.telefon}</span>:''}
    
    
                </div>
    
                <div className="formular_component">
                    <label htmlFor="info">
                        Informații adiționale
                    </label>
                    <textarea name="info" value={formik.values.info} onChange={formik.handleChange} onBlur={formik.handleBlur}></textarea>
                </div>
    
                <p>
                
                <button type="submit" className="form-btn">{submitBtn?<img alt="" src={loading} width="35em"></img>:"Trimite"}</button>
                </p>
                </form>
                <ToTopBtn></ToTopBtn>
            </div>
        )
    }
    else{
            return(
                <div className="page-body-content">
                <h1>Formular de înscriere</h1>
                <div className="success-container">
                <img width={100} src={success}></img>

<h2>Vă multumim!</h2>
<h3>Formularul de înscriere a fost finalizat cu succes, în scurt timp vom reveni.</h3>
                </div>
               
            </div>
            )
    }

   
}