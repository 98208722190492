import "../../components/FormStyle.css";
import { useState } from "react";
import { useFormik } from "formik";

import * as Yup from "yup";
import "../../components/ButtonStyle.css";
import "../../components/FormStyle.css";
import React from "react";
import { useRef } from "react";
import loading from "../../images/Spinner-1.6s-200px.gif"
import emailjs from '@emailjs/browser';

import success from "../../images/check-mark.png"
import ToTopBtn from "../../components/toTopBtn/toTopBtn";

const sleep = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

export default function FormContact(){

    const [isSubmit,setIsSubmit]=useState(false);

    const [submitBtn,setSubmitBtn]=useState(false);

    // function goToTop(){ useEffect(()=>{
    //   })}

    const formik= useFormik({
        initialValues:{
            nume:'',
            prenume:'',
            telefon:"",
            email:"",
            subiect:"",
            mesaj:"",

        },

        //validate form
        
        validationSchema:Yup.object({
            nume:Yup.string().max(20,"Maxim 20 caractere.").required("Acest câmp este obligatoriu."),
            prenume:Yup.string().max(20,"Maxim 20 caractere.").required("Acest câmp este obligatoriu."),
            subiect:Yup.string().max(100,"Maxim 100 caractere.").required("Acest câmp este obligatoriu."),
            mesaj:Yup.string().required("Acest câmp este obligatoriu."),
            email: Yup.string().email("Adresă de email invalidă").required("Acest câmp este obligatoriu."),
            telefon: Yup.string().matches(/^\+?[0-9]+$/,"Număr de telefon invalid.").required("Acest câmp este obligatoriu."),
        }),

        onSubmit:async (values)=>{

            setSubmitBtn(true);
          


            await emailjs.send('service_nk0ej0u', 'template_x6woh0h', 
                values, 'aX8I-MQ3QFijUGNbq')
                .then(() => {
              console.log('email sent');
            });
            //await sleep(10000)


            setIsSubmit(true);

            window.scrollTo({top:0,left:0,behavior:"smooth"});

            // setIsSubmit(false)
            formik.resetForm()
            setSubmitBtn(false)
      
        }
    })

    if(isSubmit===false){
        return(
            <div className="page-body-content">
                <h1>Formular de contact</h1>
                <p></p>

                <p>
                Așteptăm să ne scrieți sugestii, feedback-uri pentru activitatea noastra din cadrul cursurilor și atelierelor.
                </p>
                <p>
                Ne puteți adresa întrebari cu privire la activitatea noastră, întrebări din situațiile cotidiene legate de educația copiilor, relaționării lor cu semenii săi și/sau cu adulți.
                </p>
                <p>
                Vă rugăm să completaţi rubricele formularului corect, pentru a putea interacţiona cu noi.  (fiecare rubrica este obligatorie.)
                </p>
                <p></p>
                <form onSubmit={formik.handleSubmit}>

                <div className="formular_component">
                    <div className="linie">
                        <div className="col">
                            <label htmlFor="nume">
                                Nume
                            </label>
                            <input required type="text" name="nume" placeholder="Nume" value={formik.values.nume} onChange={formik.handleChange} onBlur={formik.handleBlur}></input>
                            {formik.touched.nume && formik.errors.nume ? <span className="err">{formik.errors.nume}</span>:''}
    
                        </div>
                        <div className="col">

                            <label htmlFor="prenume">
                                Prenume
                            </label>
                            <input required type="text" name="prenume" placeholder="Prenume" value={formik.values.prenume} onChange={formik.handleChange} onBlur={formik.handleBlur}></input>
                            {formik.touched.prenume && formik.errors.prenume ? <span className="err">{formik.errors.prenume}</span>:''}
            
                        </div>

                    </div>
                </div>

                <div className="formular_component">
                    <div className="linie">
                        <div className="col">
                        <label htmlFor="email">
                        Email
                    </label>
                    <input required type="text" name="email" placeholder="example@example.com" value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur}></input>
                    {formik.touched.email && formik.errors.email ? <span className="err">{formik.errors.email}</span>:''}
    
                        </div>
                        <div className="col">

                        <label htmlFor="telefon">
                        Număr de contact
                    </label>
                    <input required type="tel" name="telefon" placeholder="+373" value={formik.values.telefon} onChange={formik.handleChange} onBlur={formik.handleBlur}></input>
                    {formik.touched.telefon && formik.errors.telefon ? <span className="err">{formik.errors.telefon}</span>:''}
    
    
                        </div>

                    </div>
                </div>


                <div className="formular_component">
                    <label htmlFor="subiect">
                        Subiect
                    </label>
                    <input id="subject" required type="text" name="subiect" placeholder="Subiect" value={formik.values.subiect} onChange={formik.handleChange} onBlur={formik.handleBlur}></input>
                    {formik.touched.subiect && formik.errors.subiect ? <span className="err">{formik.errors.subiect}</span>:''}
    
    
                </div>
    
                <div className="formular_component">
                    <label htmlFor="mesaj">
                        Mesaj
                    </label>
                    <textarea name="mesaj" value={formik.values.mesaj} onChange={formik.handleChange} onBlur={formik.handleBlur}></textarea>
                </div>

                <p>
                
                <button type="submit" className="form-btn">{submitBtn?<img alt="" src={loading} width="35em"></img>:"Trimite"}</button>
                </p>
                </form>
                <ToTopBtn></ToTopBtn>
    
            </div>
        )
    }
    else{
        return(
            <div className="page-body-content">
            <h1>Formular de contact</h1>
            <div className="success-container">
            <img width={100} src={success}></img>

<h2>Vă multumim!</h2>
<h3>Formularul a fost finalizat cu succes, în scurt timp vom reveni.</h3>
            </div>
            <ToTopBtn></ToTopBtn>

        </div>
        )
    }
}