import Elfried from "../PrincipiiPedagocice/Elfried"
import Image from "../../components/Image"
import foto_1 from "../../images/misiune/misiune_1.jpg"
import foto_2 from "../../images/misiune/misiune_2.jpg"
import ToTopBtn from "../../components/toTopBtn/toTopBtn"
export default function Despre(){
    return<>
        <div className="page-body-content">
            <h1>Misiune și valori</h1>
            <p></p>
            <p></p>
            <div className="row-img">
                <div className="column">
                <h3><span className="accent-color-1">EduCasa</span> este locul unde teoria se împletește cu practica. Este spațiul unde copiii
acționează din proprie inițiativă, fiind ghidați acolo unde au nevoie de suport, de
către pedagogele instruite Pikler. Este spațiul unde părinții și actorii educaționali
primesc suport în educarea și îngrijirea copiilor.</h3>
                    <p>
                    Activitatea <span className="accent-color-1">EduCasa</span> se bazează pe principiile pedagogice pediatrei ungare Emmi
                    Pikler, precum și a bunelor ei colege,<a href="../principiile_pedagocice/Elfriede_Hengstenberg"> <span className="action-color-1">Elfriede Hengstenberg</span></a> și <a href="../principiile_pedagocice/Ute_Strub"> <span className="action-color-1">Ute Strub</span></a>.
                    </p>
                    <p>
            <span className="accent-color-1">Scopul EduCasa</span> este promovarea educației timpurii a copiilor de 0-7 ani, cu respect
și afecțiune chiar de la naștere - ca om și partener, prin îmbunătățirea și
aprofundarea cunoștințelor actorilor educaționali (părinți, îngrijitori, educatori din
sectorul public și privat, asistenți sociali, ș.a.) în educarea și dezvoltarea timpurie a
copiilor.
            </p>
                </div>
                <Image src={foto_2} phoneWidth="60%" phoneMaxWidth="250px" tabletWidth="43%" desktopWidth="39%"></Image>

            </div>
            <p>
            <span className="accent-color-1">Misiunea EduCasa</span> este să facem auzit impactul educației conștiente asupra
viitorului pașnic și echilibrat emoțional al copiilor de azi (iubire de sine, încredere și
stimă de sine, compasiune, toleranta, empatie, afecțiune, etc.), prin implicarea și
prezența conștientă a părinților în activitățile copiilor săi, precum și prin instruirile și
consilierile specializate pentru această vîrstă educațională – în susținerea actorilor
educaționali.
            </p>
            <p></p>
            <div className="row-img">

 
<Image src={foto_1} phoneWidth="80%" phoneMaxWidth="400px" tabletWidth="44%" desktopWidth="42%"></Image>

                <div className="column">
                    <h3><span className="accent-color-1">Valorile EduCasa</span> sunt bazate pe tratarea fiecărui om cu mult respect chiar de la
naștere. Cursurile noastre sunt astfel concepute, încât adultul însoțitor al copilului,
însușește atitudini și comportamente benefice dezvoltării armonioase a copilului:
tratarea copiilor și adulților cu respect, compasiune, afecțiune, empatie și iubire.</h3>
            <p><span className="accent-color-1">EduCasa</span> este un spațiu inovativ pentru educația timpurie din Republica Moldova.</p>
                </div>
            </div>
            
            <ToTopBtn></ToTopBtn>
        </div>
    </>
}

