
import VideoHeader from "../../components/homeHeader/videoHeader"
import ToTopBtn from "../../components/toTopBtn/toTopBtn"
import "./EduCasaStyle.css"

export default function Acasa(){
    return<>
    <div className="page-body-content">
    <VideoHeader></VideoHeader>

        <div className="letter-container">
                Dragi părinți și viitori părinți,
                <p>Dragi actori educaționali,</p>
                <p>
                    în primii ani de viață ai unui copil, pe prim-plan sunt dezvoltarea sa fizică,
                    mișcarea liberă, propriul simț și explorarea. Copilul se descoperă ca individ și
                    face cunoștință cu sine și abilitățile sale.
                </p>
                <p>
                    În<span className="accent-color-1"> EduCasa</span> copiii și părinții au parte de siguranță, protecție și vizibilitate. E
                    spațiul și timpul întâlnirii, mișcării și evoluției. E locul în care se simte
                    entuziasmul pentru minunea vieții.
                </p>
                <p>
                    Vrem să încurajăm părinții, să privească lumea prin ochii copilașilor săi. Dintr-
                    o energie plină de blândețe cu sine, adulții îngrijitori pot recunoaște
                    necesitățile adevărate ale copilului și îl pot însoți cu multă tandrețe și
                    afecțiune pe drumul lui în viață.
                </p>
                <p>
                    Vă mulțumim pentru încrederea voastră în echipa noastră și în tot ceea ce
                    putem oferi din inimă pentru inimi! Ușile<span className="accent-color-1"> EduCasa</span> sunt larg deschise pentru
                    voi, dragi copii, părinți, educatori!!!
                </p>
                <p>Cu multă iubire,</p>
                <p>Ana Bordos-Boldișor</p>
                <p>Fondatoarea<span className="accent-color-1"> EduCasa</span></p>

                <ToTopBtn/>
        </div>
    </div>
    </>
}

