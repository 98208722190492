import "./SubsolStyle.css";
import "../../App.css";
import "../ButtonStyle.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faFacebook,faInstagram} from "@fortawesome/free-brands-svg-icons"
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { faP, faPhone } from '@fortawesome/free-solid-svg-icons'
import { useState,useLayoutEffect } from "react";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";

export default function Subsol(){
    // const [screenWidth, setScreenWidth] = useState(1024);

    // useLayoutEffect(() => {
    //     setScreenWidth(window.innerWidth);
    //     window.addEventListener('resize', () => setScreenWidth(window.innerWidth));
    //     return () => window.removeEventListener('resize', () => setScreenWidth(window.innerWidth));
    // }, []);

    const [tablet, setTablet] = useState(false);

    
    useLayoutEffect(() => {
        setTablet(window.matchMedia("(max-width: 1100px) and (min-width:770px) ").matches);
        window.addEventListener('resize', () => setTablet((window.matchMedia("(max-width: 1100px) and (min-width:770px) ").matches)));
        return () => window.removeEventListener('resize', () => setTablet((window.matchMedia("(max-width: 1100px) and (min-width:770px) ").matches)));
    }, []);

    if( !tablet){
    return(
        <>
        <div className="subsol-container subsol-color">
            <div className="subsol-content">
            <ul>
                <li> 
                    <div className="title accent-color-1"> Găsește-ne: </div>
                    <div className="item">
                        <div className="row">
                        <h4>
                        <FontAwesomeIcon icon={faLocationDot}color="#E88C8a" />
                        </h4> 
<h4>Str-la. 1 Balcani 11A, or. Durlești, mun. Chișinău</h4>
                        </div>
                            <div className="subsol-btn">
                                <a rel="noopener" href="../contacte#locatie">Vezi Harta</a>
                            </div>

                    </div>
                </li>
                <li>
                    <div className="title accent-color-1"> Contacte: </div>
                    <div className="item">
                        <div className="row">
                            <a href="tel:+37369367003"><h4 className="action-color-2"> +373 69367003</h4> </a> <h4>Ana Bordos-Boldişor</h4>
                        </div>
                        <div className="row">
                        <a href="tel:+37369367003">
                        <div className="social-btn">
                        <FontAwesomeIcon icon={faPhone} size="lg"/>

                        </div>
                        </a>
                        <a href="../formular_de_contact">

                        <div className="social-btn">

                        <FontAwesomeIcon icon={faEnvelope} size="lg" />
                            </div>  
                            </a>                       

                <a href="https://www.facebook.com/EduCasaPikler/" target="_blank">
                        <div className="social-btn">
                        <FontAwesomeIcon icon={faFacebook} size="xl" />
                        </div>
                        </a>
                        <a href="https://www.instagram.com/educasa.pikler/" target="_blank">
                        <div className="social-btn">
                        <FontAwesomeIcon icon={faInstagram} size="xl" />
                        </div>
                        </a>
                        </div>
                    </div>
                    
                </li>
                <li>
                    <div className="title accent-color-1"> Impressum: </div>
                    <div className="item">
                        <h4>Responsabilă pentru conținut:</h4>

                        <h4>Ana Bordos-Boldișor</h4>

                        <div className="row">
                            <h4>
                        <FontAwesomeIcon icon={faEnvelope}color="#E88C8a" />
                        </h4>
                            <a href="mailto:ana.bordos@educasa.org">
                                <h4 className="action-color-2"> ana.bordos@educasa.org</h4>
                            </a>
                        </div>

                        <div className="row">
                            <h4>
                        <FontAwesomeIcon icon={faPhone}color="#E88C8a" />
                        </h4>
                            <a href="tel:+37369367003">
                                <h4 className="action-color-2">+373 69367003
                                </h4>
                            </a>
                            
                        </div>
                    </div>
                </li>
            </ul>
            </div>
        </div>
        </>
    )}
    else{
        return(
            <>
            <div className="subsol-container subsol-color">
            <div className="subsol-content">
            <ul>
                <li> 
                    <div className="title accent-color-1"> Găsește-ne: </div>
                    <div className="item">
                        <div className="row">
                        <h4>
                        <FontAwesomeIcon icon={faLocationDot}color="#E88C8a" />
                        </h4>                        
                        <h4>Str-la. 1 Balcani 11A, or. Durlești, mun. Chișinău</h4>

                        </div>
                        <a>
                            <div className="subsol-btn">
                                Vezi Harta
                            </div>
                        </a>

                    </div>
                </li>
                <li>
                    <div className="title accent-color-1"> Contacte: </div>
                    <div className="item">
                        <div className="row">
                            <a href="tel:+37369367003"><h4 className="action-color-2"> +373 69367003</h4> </a> <h4>Ana Bordos-Boldişor</h4>
                        </div>
                        <div className="row">
                        <a href="tel:+37369367003">
                        <div className="social-btn">
                        <FontAwesomeIcon icon={faPhone} size="lg"/>

                        </div>
                        </a>
                        <a href="../formular_de_contact">
                        <div className="social-btn">

                        <FontAwesomeIcon icon={faEnvelope} size="lg" />
                            </div>            </a>             

                <a href="https://www.facebook.com/EduCasaPikler/" target="_blank">
                        <div className="social-btn">
                        <FontAwesomeIcon icon={faFacebook} size="xl" />
                        </div>
                        </a>
                        <a href="https://www.instagram.com/educasa.pikler/" target="_blank">
                        <div className="social-btn">
                        <FontAwesomeIcon icon={faInstagram} size="xl" />
                        </div>
                        </a>

                       
                        </div>
                    </div>
                    
                </li>
                
            </ul>
            <div className="subsol-secondLine">
            <li>
                    <div className="title accent-color-1"> Impressum: </div>
                    <div className="item">
                        <h4>Responsabilă pentru conținut:</h4>

                        <h4>Ana Bordos-Boldișor</h4>

                        <div className="row">
                        <h4>
                        <FontAwesomeIcon icon={faEnvelope}color="#E88C8a" />
                        </h4>
                            <a href="mailto:ana.bordos@educasa.org">
                                <h4 className="action-color-2"> ana.bordos@educasa.org</h4>
                            </a>
                        </div>

                        <div className="row">
                        <h4>
                        <FontAwesomeIcon icon={faPhone}color="#E88C8a" />
                        </h4>
                            <a href="tel:+37369367003">
                                <h4 className="action-color-2">+373 69367003
                                </h4>
                            </a>
                            
                        </div>
                    </div>
                </li>
            </div>
            </div>
        </div>
            </>
        )
    }
}