

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretRight } from "@fortawesome/free-solid-svg-icons";

export default function FindMore({text, isClicked, setIsClicked}){

    return(
        <div className="row">
                <span className="accent-color-1">
                {isClicked?<FontAwesomeIcon icon={faCaretDown}></FontAwesomeIcon>:<FontAwesomeIcon icon={faCaretRight}></FontAwesomeIcon>}
                </span>
                    <span style={{fontWeight:"bold"}} className="action-color-5" onClick={()=>{setIsClicked(!isClicked)}}>
                        {text}
                </span>

        </div>
    )

}