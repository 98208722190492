import "./ListStyle.css";

export default function List({lista=[]}){
    return(
        <>
        <ul>
             {lista&&lista.map((item,index)=>{
                            return(
                                <li key={index}>
                                        <span style={{color:"#191919"}}>{item}</span>
                                </li>
                            )
                        })}
        </ul>
        </>
    )
}