export const navItems=[
    {name: "EduCasa",path:"/",subItems:[
        {name:"Misiune și valori",path:"/misiune_și_valori"},
        {name:"Istoria",path:"/istoria"},
        {name: "Înscrie-te",path:"/înscrie-te"},
    ]},
    {name: "Principiile Pedagocice", subItems:[
        {name:"Emmi Pikler",path:"/principiile_pedagocice/Emmi_Pikler"},
        {name:"Elfriede Hengstenberg",path:"/principiile_pedagocice/Elfriede_Hengstenberg"},
        {name:"Ute Strub",path:"/principiile_pedagocice/Ute_Strub"}
    ]},
    {name: "Servicii", subItems:[
        {name:"KinderSpielRaum: Curs părinte-copil",path:"/servicii/curs_părinte-copil"},
        {name:"Cămara de joacă cu nisip",path:"/servicii/cămara_de_joacă_cu_nisip"},
        {name:"Curs cunoașterea de sine",path:"/servicii/curs_de_cunoaștere_de_sine"},
        {name:"Curs de educație cu blândețe",path:"/servicii/curs_de_educație_cu_blândețe"},
        {name:"Ateliere serale pentru părinți",path:"/servicii/ateliere_serale"},
        {name:"Consiliere individuală",path:"/servicii/consiliere_individuală"},
        // {name:"Mini-Educasa",path:"/servicii/mini-educasa"},
    ]},
    {name: "Actual",subItems:[
        {name:"Noutăți/Oferte",path:"/noutăți_oferte"},
        {name:"Presa",path:"/actual/presa"},
        {name:"Evenimente",path:"/actual/evenimente"},
        {name:"Literatură",path:"/actual/literatură"},
        {name:"Linkuri",path:"/actual/linkuri"}
    ]},
    {name: "Despre Noi",subItems:[
        {name:"Ana Bordos-Boldișor",path:"/Ana_Bordos_Boldișor"},
        {name:"Alla Ceban",path:"/Alla_Ceban"}
    ]},
    {name: "Contacte",path:"/contacte"},
]    
