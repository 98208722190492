import { useState } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import Image from "../../components/Image";
import foto from "../../images/despre/Ana_Bordos.jpg"
import ToTopBtn from "../../components/toTopBtn/toTopBtn";
export default function AnaBordos(){

    const [isClicked, setIsClicked]=useState(false);

    const handleClick=(e)=>{
        setIsClicked(!isClicked)
    }

    return(
        <div className="page-body-content">
            <h1>Ana Bordos-Boldișor</h1>
            <p/>
            <p></p>
            <div className="row-img-1">
            <Image src={foto} phoneWidth="60%" phoneMaxWidth="250px" tabletWidth="44%" desktopWidth="32%"></Image>

            <ul>
                    <li>
                        <span style={{color:"#191919", fontWeight:"bold"}}> Pedagogă după Pedagogia Pikler
                        </span>
                            <ul>

                                <li>
                                <span style={{color:"#191919"}}>Studii și formări continue, Societatea PIKLER-HENGSTENBERG-GESELLSCHAFT din Austria</span>
                                </li>

                                <li>
                                    <span style={{color:"#191919"}}>Instruire<span style={{fontWeight:"bold"}}> Calitatea relațiilor-rolul situațiilor de îngrijire în educație</span>, Institutul Pikler din Budapest
                                    </span>
                                </li>

                                <li>
                                    <span style={{color:"#191919"}}>Instruire<span style={{fontWeight:"bold"}}> Dezvoltarea joacei libere</span>, Institutul Pikler din Budapest
                                    </span>
                                </li>

                                <li>
                                    <span style={{color:"#191919"}}>Instruire<span style={{fontWeight:"bold"}}> Mișcările la copil și calitatea vieții</span>, Institutul Pikler din Budapest
                                    </span>
                                </li>

                            </ul>
                    </li>

                    <li>
                    <span style={{color:"#191919"}}>
                    <span style={{fontWeight:"bold"}}>Supervizoare, coach, trainer</span> în practici socio-educaționale
                        focusate pe soluții (Institutul pentru practici sociale-      Netzwerk OS‘T, Vienna)
                        </span>
                    </li>

                    <li>
                    <span style={{color:"#191919"}}>
                        Certificată în<span style={{fontWeight:"bold"}}> Managementul Social</span> (Academia pentru Management Social, Viena Austria)
                        </span>
                    </li>

                </ul>

            </div>
                <ul className="bottom-foto-list">
                <li>
                    <span style={{color:"#191919"}}>
                        Master în Dreptul Muncii
                        </span>
                    </li>


                    <li>
                    <span style={{color:"#191919"}}>
                        Master în Științe ale Educației, Limba și Cultura Germană
                        </span>
                    </li>

                    <li>
                    <span style={{color:"#191919"}}>
                        Licență în Științele Educației, Limba și Literatura Germană și Engleză
                        </span>
                    </li>
                </ul>


                

            <h2>

            <p>
                    Experiență profesională:
            </p>
            </h2>

            <p>            </p>

                
            <ul>
                    <li>
                        <span style={{color:"#191919"}}>
                            Fondatoare                        
                        </span> EduCasa
                    </li>
                    <li>
                    <span style={{color:"#191919"}}>

                    Profesoară de limbă germană
                    </span>
                    </li>
                    <li>
                    <span style={{color:"#191919"}}>

                    Administratoare/ Directoare firmă în antreprenoriatul social
                    </span>
                    </li>
                    <li>
                    <span style={{color:"#191919"}}>
                    PR Manager în organizație neguvernamentală austriacă
                    </span>
                    </li>
                    <li>
                    <span style={{color:"#191919"}}>
                    Specialistă Resurse Umane în organizație neguvernamentală austriacă
                    </span>
                    </li>
                    <li>
                    <span style={{color:"#191919"}}>
                    Freelancer în domeniul social
                    </span>
                    </li>
                </ul>

                <p>                </p>

                <div className="row">
                    <span className="accent-color-1">
                {isClicked?<FontAwesomeIcon icon={faCaretDown}></FontAwesomeIcon>:<FontAwesomeIcon icon={faCaretRight}></FontAwesomeIcon>}
                </span>
                    <span style={{fontWeight:"bold"}} className="action-color-5" onClick={handleClick}>
                            aici descoperiți mai multe:
                    </span>

                </div>
                {isClicked?<FindMore/>:""}
                <ToTopBtn></ToTopBtn>

        </div>
    )
}

function FindMore(){
    return(
        <><p>
            Oscilez ușor între<span className="accent-color-1"> perfecțiune și realitate</span>. Încerc să îmi găsesc echilibrul, căci ambele îmi dau puterea și curajul de a tinde spre scopurile și visele mele. Mă inspir cu drag de la tot ce mă înconjoară: natura, oamenii, provocările și situațiile trăite zi de zi.
        </p><p>
                O adolescentă, din inima Codrilor Moldovei, cu o copilărie fericită, absolvesc în vara lui 2010 Facultatea de Limbi Străine de la Universitatea Pedagogică din Chișinău. Îndată încadrată în activitatea unei organizații neguvernamentale de susținere a copiilor proveniți din familii cu situații precare, precum și a oamenilor în etate. A fost<span className="accent-color-1"> o mare provocare</span>, după teorii și ore în șir de pedagogie și psihologie să mă trezesc în situații reale de viață.
            </p><p>
                Fiind responsabiă pe sectorul „maternități și concedii de îngrijire a copilului” am studiat paralel Dreptul Muncii la Universitatea de Stat din capitală. Mă simțeam <span className="accent-color-1">vocea</span> celor peste 50 angajate – <span className="accent-color-1">viitoare mămici</span> și femei aflate în perioada concediului de îngrijire a copilului.
            </p><p>
                Setea mea de a fi prezentă acolo unde este „foc” și a găsi soluții pentru situațiile dificile, m-a determinat să explorez<span className="accent-color-1"> practicile europene</span> și să studiez începînd cu anul 2013 timp de cinci semestre, Cursurile de formare și dezvoltare profesională la <span className="accent-color-1">Academia de Management Social din Viena</span>. Întrebări cu multe răspunsuri, incertitudine, provocări culturale și sociale, experiențe miraculoase,dorința de implementare, toate fierbeau în mine. Astfel am descoperit Respectul și Empatia ca calități esențiale în activitatea mea de conducătoare a unei echipe multinaționale (moldoveni, român și austriac) și multilaterale. O numesc <span className="accent-color-1">perioada de înflorire</span> a lăstarului din mine.
            </p><p>
                Această înflorire o datorez unui german-sași și unei austriace, și anume, șefilor mei, care au crezut în potențialul meu și m-au susținut profesional și personal.
            </p><p>
                Simțeam în mine capacitatea de a face mai mult pentru oameni, pentru angajați/te, familii, părinți, copii, cadre didactice și sociale.  La Institutul pentru Supervizare, Coaching și Training din Viena am devenit<span className="accent-color-1"> supervizoare, coach și trainer</span> în practici focusate pe soluții, ceea ce stă la baza activității mele profesionale și situațiilor personale.
            </p><p>
                Vocea mea lăuntrică șoptea cu multă speranță, să nu opresc. Să creez <span className="accent-color-1">acasă</span> un spațiu deschis pentru cei, cărora le pasă de viitorul sănătos al societății noastre, la baza creia stau familiile fericite. O vizită de mai în 2018 la un centru <span className="accent-color-1">KinderSpielRaum după Emmi Pikler din Viena</span>, m-a convins că acesta este locul pe care vreau să îl am și ACASĂ, este locul, în care părinții pot observa în liniște și admirație necesitățile copiilor săi și copilașii să se bucure de libertatea de a acționa/ a se juca fără „instrucțiuni și sfaturi”.
            </p><p>
                Nu am ezitat mult și la începutul lui 2019 am început să studiez Pedagogia Pikler, care mă reprezintă, la<span className="accent-color-1"> Societatea PIKLER-HENGSTENBERG-GESELLSCHAFT</span> din Austria. Spațiul românesc, atît țara noastră, cît și România, e însetat de a primi acasă o lume a Acceptării, a Respectului și a Realității.
            </p><p>
                Cu multă dragoste și deschidere, însoțesc copiii Dumneavoastră în timpul cursului din<span className="accent-color-1"> KinderSpielRaum</span> și pe voi părinții, prin atelierele serale sau cele planificate, coaching și supervizare.
            </p></>
    )
}