import YoutubeVideo from "../../components/YoutubeVideo";
import Radio from "../../images/presa/Radio.mp3"
import Video from "../../images/presa/KinderSpielRaum.mp4"
import presa_1 from "../../images/presa/Screenshot-2022-12-12-184139-1024x540.png";
import presa_2 from "../../images/presa/presa.png";
import ToTopBtn from "../../components/toTopBtn/toTopBtn";
import Preview from "../../images/presa/preview.png"
export default function Presa(){
    return(
        <div className="page-body-content">
            <h1>Presă</h1>
            <p></p>
            <p></p>
            <div className="actual_item">
                <h2>TeleMatinal-TVR, 26.01.2023</h2>
                <p>Despre comunicarea eficientă între părinți și copii, cum ar trebui să ne educăm corect copiii, dar și despre sistemul de educație timpurie după pedagogia Pikler și principiile acesteia a discutat Ana Bordos, pedagog Pikler</p>
            <p></p>
            <YoutubeVideo src="https://www.youtube.com/embed/fCBeYM0tIak?si=UTHDjV-zFZKp2DvV&amp;start=630" name="Youtube Video"></YoutubeVideo>

            </div>
            
            <p></p>
            <div className="actual_item">
                <h2>Good Vibe cu Ana Bordos, Radio ZUM, 17.01.2023
                </h2>

                <p>
                Dragi prieteni, cunoscuți, părinți, bunici, este lungă discuția! Se merită să ascultați cap-coada, chiar și pe fundalul unei activități!
                </p>
                <p></p>
                <YoutubeVideo src="https://www.youtube.com/embed/Al3tBfXOtyg?si=LHoUYzjxdkYfK38H" name="YouTube video player" ></YoutubeVideo>

            </div>
            <p></p>
            <div className="actual_item">
                <h2>Reportaj TVR: Parteneriat între KinderSpielRaum Pikler și Misiunea Socială Diaconia, pentru susținerea mamelor solitare și din familii nevoiașe, 02.12.2022</h2>
                <p></p>
                <a style={{display:"flex",flexDirection:"column",justifyContent:"center"}} href="https://tvrmoldova.md/article/a078f9d348c9380b/misiunea-sociala-diaconia-a-mitropoliei-basarabiei-a-deschis-o-cresa-prin-care-ofera-ajutor-femeilor-aflate-in-situatii-dificile.html">
                    <img className="link" src={presa_1} style={{width:"90%",borderRadius:"0.5em",objectFit:"cover",alignSelf:"center"}}></img>
                </a>
            </div>
            <p></p>
            <div className="actual_item">
                <h2>Interviu: Educația și îngrijirea copiilor după Pedagogia Pikler, 23.10.2022</h2>
            <p></p>
            <YoutubeVideo src="https://www.youtube.com/embed/7UkR746ffHI?si=rcDr7FrnCAZXJyRI" name="YouTube video player"></YoutubeVideo>
            </div>
            <p></p>
            <div className="actual_item">
                <h2>Diez.md, 19.03.2021</h2>
                <p></p>
                <a style={{display:"flex",flexDirection:"column",justifyContent:"center"}} href="https://tvrmoldova.md/article/a078f9d348c9380b/misiunea-sociala-diaconia-a-mitropoliei-basarabiei-a-deschis-o-cresa-prin-care-ofera-ajutor-femeilor-aflate-in-situatii-dificile.html">
                    <img className="link"  src={presa_2} style={{width:"80%",maxWidth:"500px",borderRadius:"0.75em",objectFit:"cover",alignSelf:"center"}}></img>
                </a>
            </div>
            <p></p>
            <div className="actual_item">
                <h2>Teleradio Moldova, Reportaj 08.02.2021
                </h2>
                <p></p>
                <audio loading=" lazy" style={{alignSelf:"center"}} controls src={Radio}></audio>
            </div>

            <p></p>
            <div className="actual_item">
                <h2>TVR Moldova, Telejurnal 07.02.2021, ora 18.00</h2>
                <p></p>
                <video style={{alignSelf:"center",borderRadius:"0.5em", aspectRatio:"16/9"}} src={Video} poster={Preview} controls></video>
            </div>

            <ToTopBtn></ToTopBtn>
        </div>
    )
}