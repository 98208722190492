import{Link, useMatch,useResolvedPath, useNavigate} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark,faBars } from '@fortawesome/free-solid-svg-icons'

import './NavBarStyle.css';
import {useState, useLayoutEffect} from 'react';
import Logo from "../../Logo.png";
import { navItems } from "./NavItems";
import DropDown from "./DropDown";

export default function NavBar(){

    const [isDrawerOpen,setIsDrawerOpen] = useState(false);
    const [showSubMenu,setShowSubMenu]=useState([false,false,false,false,false,false])

    const toggleDrawer=()=>{
        setIsDrawerOpen(!isDrawerOpen);
    };

    const closeDrawer=(item)=>{
        if(!item.subItems)
            setIsDrawerOpen(!isDrawerOpen);
    }; 

    const handleShowSubMenu=(index,val)=>{
        var dropDownVal=[false,false,false,false,false,false];
        dropDownVal[index]=val;
        setShowSubMenu(dropDownList=>dropDownVal)
    }


  

    return <nav className="nav-bar nav-bar-color accent-color-1">
        <div className="topBanner"> 
        
        <Link to="/"><img src={Logo} onClick={()=>{setIsDrawerOpen(false);handleShowSubMenu(0,false)}} alt="" width={190}/></Link>
        
        <div className="menu-icon action-color-1" onClick={()=>{toggleDrawer(); handleShowSubMenu(0,false)}} >
            {isDrawerOpen?<FontAwesomeIcon icon={faXmark} />:<FontAwesomeIcon icon={faBars} />}
        </div>
        </div>

        <ul> 
            {navItems.map((item,index)=>{
                    return (<CustomLink key={index} index={index} item={item} showSubMenu={showSubMenu} setShowSubMenu={handleShowSubMenu} setIsDrawerOpen={setIsDrawerOpen} onClick={()=>closeDrawer(item)}>{item.name}</CustomLink>)
            })
            }            
        </ul>

        {isDrawerOpen?
        <div className={isDrawerOpen?'drawer':''}> 
        <ul>
            {navItems.map((item,index)=>{
                    return (<CustomLink key={index} index={index} item={item} showSubMenu={showSubMenu} setShowSubMenu={handleShowSubMenu} setIsDrawerOpen={setIsDrawerOpen} onClick={()=>closeDrawer(item)}>{item.name}</CustomLink>)
            })
            }       
            </ul>     
        </div>:""}

    </nav>
}




function CustomLink({item,index,showSubMenu,setShowSubMenu,setIsDrawerOpen,children,...props}){

    const [isDropDownActive,setisDropDownActive]=useState(false);
    const [mobile, setMobile] = useState(false);
    const resolvedPath=useResolvedPath(item.path)
    const isActive=useMatch({path:resolvedPath.pathname,end:true})

    useLayoutEffect(() => {
        setMobile(window.matchMedia("(max-width: 770px)").matches);
        window.addEventListener('resize', () => setMobile((window.matchMedia("(max-width: 770px)").matches)));
        return () => window.removeEventListener('resize', () => setMobile((window.matchMedia("(max-width: 770px)").matches)));
    }, []);



    // const handleClick = (e) => {
    //   if (item.path === "/") {
    //         e.preventDefault();
    //         // window.location.href = '/external.html';
    //         window.location.replace("/");
    //   }
      
    // };

    console.log(mobile);
    if(item.subItems){
        if(mobile){
            return(
                // className={isActive ? "active" : ""}
                <li >
                    <Link {...props} className="action-color-1"   onClick={()=>{setShowSubMenu(index,!showSubMenu[index]);}}>
                        {children}
                    </Link>
                    {showSubMenu[index] && <DropDown items={item.subItems} setisDropDownActive={setisDropDownActive} setShowSubMenu={setShowSubMenu} setIsDrawerOpen={setIsDrawerOpen}/>}
                </li>
            )
        }else{
            return(
                <li className={"action-color-1"} onClick={()=>{window.scroll({ top: 0,left: 0, behavior: "smooth",});setisDropDownActive(!isDropDownActive)}} onMouseEnter={()=>{setisDropDownActive(true)}}  onMouseLeave={()=>{setisDropDownActive(false)}}>
                    <Link to={item.path?item.path:"#"} {...props}>
                        {children}
                    </Link>
                    {isDropDownActive && <DropDown items={item.subItems} setisDropDownActive={setisDropDownActive} setShowSubMenu={setShowSubMenu} setIsDrawerOpen={setIsDrawerOpen}/>}
                </li>
            )
        }
    }else{
        if(mobile){
            return (
                <li>
                    <Link to={item.path} className="action-color-1" {...props}  onClick={()=>{window.scroll({ top: 0,left: 0, behavior: "smooth",});setIsDrawerOpen(false); setShowSubMenu(0,false)}}>
                        {children}
                    </Link>
                </li>
            )
        }else{
            return (
                //isActive ? "active" : 
                <li className={"action-color-1" }>
                    <Link to={item.path} {...props} onClick={()=>window.scroll({ top: 0,left: 0, behavior: "smooth",})}>
                        {children}
                    </Link>
                </li>
            )
        }
       
    }
}