

export default function YoutubeVideo({src, name,start}){

    return(
        <>
            <iframe style={{borderRadius:"0.5em", aspectRatio:"16/9",alignSelf:"center"}} width="90%" 
            src={src}
            
             loading=" lazy"
            title={name}
             frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </>
    )
}