

import Image from "../../components/Image";
import ToTopBtn from "../../components/toTopBtn/toTopBtn";
import foto from "../../images/despre/Ala.jpg"

export default function AllaCeban(){

    return(
        <div className="page-body-content">
            <h1>Alla Ceban</h1>
            <p>            </p>
            <p></p>
            
            <div className="row-img-1">
                <Image src={foto} phoneWidth="60%" phoneMaxWidth="250px" tabletWidth="43%" desktopWidth="34%"></Image>
                <ul>
                    <li>
                        <span style={{color:"#191919", fontWeight:"bold"}}> 
                            Pedagogă după Pedagogia Pikler
                        </span>

                        <ul>

                            <li>
                                <span style={{color:"#191919"}}> 
                                Studii și formări continue, Societatea PIKLER-HENGSTENBERG-GESELLSCHAFT din Austria
                                </span>
                            </li>

                            <li>
                                <span style={{color:"#191919"}}> 
                                Instruire <span style={{fontWeight:"bold"}}>  Calitatea relațiilor-rolul situațiilor de îngrijire în educație</span>, Institutul Pikler din Budapest
                                </span>
                            </li>

                            <li>
                                <span style={{color:"#191919"}}>
                                Instruire  <span style={{fontWeight:"bold"}}> Dezvoltarea joacei libere</span>, Institutul Pikler din Budapest 
                                </span>
                            </li>

                            <li>
                                <span style={{color:"#191919"}}> 
                                Instruire <span style={{fontWeight:"bold"}}>  Mișcările la copil și calitatea vieții</span>, Institutul Pikler din Budapest
                                </span>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <span style={{color:"#191919"}}>
                            <span style={{fontWeight:"bold"}}> 
                        Supervizare</span> in practici socio-educaționale focusate pe soluții (Institutul pentru practici sociale-Netzwerk OS°T, Viena)
                        </span>
                    </li>
                </ul>
            </div>
            <ul className="bottom-foto-list">
           

                    <li>
                        <span style={{color:"#191919"}}> 
                        Curs International Intensiv de Comunicare Non-Violentă după principiile lui Marshal Rosenberg
                        </span>
                    </li>

                    <li>
                        <span style={{color:"#191919"}}> 
                        Master în Științe ale Educației, Limba și Cultura Germană
                        </span>
                    </li>

                    <li>
                        <span style={{color:"#191919"}}> 
                        Licența în Științele Educației, Limba și Literatura Germana și Engleză
                        </span>
                    </li>
            </ul>

               
            <h2>

            <p>
                    Experiență profesională:

            </p>
            </h2>

            <p>            </p>

                
            <ul>
                    <li>
                        <span style={{color:"#191919"}}>
                        Profesoară de limba germană și engleză
                        </span>
                    </li>

                    <li>
                        <span style={{color:"#191919"}}>
                        Manager Academie (organizație austriacă), responsabilă pentru formarea continuă a angajaților/telor
                        </span>
                    </li>
            </ul>
            <ToTopBtn></ToTopBtn>

        </div>
    )
}