import video from "../../images/EduCasaVideo.mp4"
import foto_3 from "../../images/carusel/carusel_3.jpg"
import ReactPlayer from "react-player"

export default function VideoHeader(){
   
    return(
        <>

            {/* <div style={
                {
                    marginBottom:"2em",
                    width: "100%",
                    aspectRatio:"900/600",
                    display:"flex",
                    justifyContent:"center",
                    alignItems:"center",
                    borderRadius:"1em"
                }
            }>

            </div>
            <div style={
                {
                    marginBottom:"2em",
                    width: "100%",
                    aspectRatio:"900/600",
                    display:"flex",
                    justifyContent:"center",
                    alignItems:"center",
                    borderRadius:"1em"
                }
            }>
                
                <ReactPlayer
                    url={video}
                    width={"100%"}
                    height={"100%"}
                    borderRadius={"1em"}
                    controls
                    light={foto_3}
                    loop
                    playing
                    muted
                    style={
                        {
                            borderRadius:"1em",
                        }
                    }
                >

                </ReactPlayer>
            </div> */}
            <div style={ 
                {
                    marginBottom:"2em",
                    width: "100%",
                    aspectRatio:"900/600",
                    display:"flex",
                    justifyContent:"center",
                    alignItems:"center",
                }}>

                <video style={{objectFit:"cover",alignSelf:"center",borderRadius:"1em", aspectRatio:"16/9",width:"100%"}} src={video} poster={foto_3} controls autoPlay muted loop preload="auto"></video>
            </div>
        </>

    )
}