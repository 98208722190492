import ToTopBtn from "../../components/toTopBtn/toTopBtn"
import ElfriedeFoto from "../../images/ElfriedeHengstenberg.jpg"

export default function Elfried(){
    return(
        <>
        <div className="page-body-content">
            <div className="header">
                    <div className="foto" style={{backgroundImage:`url(${ElfriedeFoto})`, borderRadius:"0.5em"}}></div>
                    <div className="column">
                        <h1>Elfriede Hengstenberg</h1>
                        <p>
                        Elfriede Hengstenberg (1892-1992) a fost profesoară de gimnastică în Berlin, unde a lucrat cu copiii din oraș.    Preocuparea ei a fost ca copiii să poată descoperi și dezvolta în mod independent abilitățile de
                        mișcare.
                        </p>
                        <p>
                            A fost elevă a Elsei Gindler și Heinrich Jacoby. Ea a lucrat atât cu adulți, cât și cu copii. Ea a cunoscut-o pe Emmi Pikler în timpul unui seminar în Ungaria, în anul 1930.
                            Pe baza experiențelor lor cu copiii și a
            perspectivelor comune, Elfriede Hengstenberg și Emmi Pikler, au dezvoltat principii pedagogice similare.
                        </p>
                    </div>
                    
            </div>
            
            <p>
            Materialele de mișcare Hengstenberg provin din natură: scări, taburete, stâlpi de echilibrare... acestea
            invită copiii să se târască, să se cațere, să se echilibreze, să alunece, să sară... Toate materialele pot fi
            combinate și servesc ca elemente de construcție versatile și mobile. Pe de o parte, ele oferă copiilor
            posibilitatea de a construi dispozitive de mișcare, pe care să le exploreze în propriul ritm și cu propria lor
            dinamică și care le oferă spațiu de a-și dezvolta îndemînarea și bucuria mișcării.
            </p>
            <p style={{fontStyle:"italic"}}>
            „Așa că practic îi las pe copii să cerceteze și să descopere în mod independent... și pregătește obiecte și
            dispozitive care să-i ademenească pe copii să experimenteze cu ele.”            </p>
            <p style={{textAlign:"center"}}>- Elfriede Hengstenberg -</p>
            <p>
            Copiii se experimentează pe ei înșiși cu întregul său corp. Mergând desculți, ei percep diferitele suprafețe
            prin tălpile picioarelor și descoperă vitalitatea degetelor de la picioare, cu care își asigură o ținută stabilă.
            Copiii vor putea transfera toate aceste experiențe în viața de zi cu zi: Cum mă comport, când lucrurile
            devin șubrede? Cum să cad? Mă pot întoarce dacă am luat prea multe. Pot să-mi iau atît timp, cît am
            nevoie.
            </p>
            <ToTopBtn></ToTopBtn>
        </div>
        </>
    )
}