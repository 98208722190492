
import "./ContacteStyle.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faFacebook,faInstagram} from "@fortawesome/free-brands-svg-icons"
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";

import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import ToTopBtn from "../../components/toTopBtn/toTopBtn";

export default function Contacte(){
    return(
        <>
            <div className="page-body-content">
                <h1>Contacte</h1>
                <p></p>
                <p></p>
                    <div className="row-img" style={{justifyContent:"space-between"}}>
                        <div className="column">
                            <h2>Fondatoare Educasa</h2>
                            <span style={{fontWeight:"bold"}}>Ana Bordos-Boldișor, pedagogă Pikler i.A.</span>
                            <div className="row">
                            <h3>
                            <FontAwesomeIcon icon={faPhone}/>
                            </h3>                             <a href="tel:+37369367003">
                                        <span className="action-color-1"> +373 69367003
                                        </span>
                                    </a>
                                    
                            </div>
                            <div className="row">
                            <h3>
                            <FontAwesomeIcon icon={faEnvelope}/>
                            </h3>                             <a href="mailto:ana.bordos@educasa.org">
                                        <span className="action-color-1">  ana.bordos@educasa.org</span>
                                    </a>             
                            </div>
                        </div>

                        <div className="column">
                        <h2>Informații</h2>

<div className="row">
    <h3>
<FontAwesomeIcon icon={faEnvelope}/>
</h3>      
        <a href="../formular_de_contact">

            <span className="action-color-1">  info@educasa.org</span>
        </a>             
</div>
<div className="row">
        <a href="https://www.facebook.com/EduCasaPikler/" target="_blank">
    <div className="social-btn-1">
    <FontAwesomeIcon icon={faFacebook} size="xl" />
    </div>
    </a>
    <a href="https://www.instagram.com/educasa.pikler/" target="_blank">
    <div className="social-btn-1">
    <FontAwesomeIcon icon={faInstagram} size="xl" />
    </div>
    </a>
</div>
                        </div>
                    </div>
                   
                   
                    <p></p>

                <h1 id="locatie"><p>Locație</p></h1>
                <p>                </p>

                <div className="row">
                <h3>
                <FontAwesomeIcon icon={faLocationDot}/>
                </h3>

                Str-la. 1 Balcani 11A, or. Durlești, mun. Chișinău
            
                </div>
                <p></p>
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10876.04768089385!2d28.7549641!3d47.0399969!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40cbd79a1436fa0b%3A0xa0bd9ca79da4fa28!2sEduCasa!5e0!3m2!1sro!2s!4v1694308732759!5m2!1sro!2s" width="100%" 
                height="450" style={{width:"100%",border:"0", borderRadius:"1em"}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

                <ToTopBtn></ToTopBtn>
            </div>
        </>
    )
}

