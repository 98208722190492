import ToTopBtn from "../../components/toTopBtn/toTopBtn";


export default function Link(){

    return(
        <div className="page-body-content">
            <h1>
                Linkuri
            </h1>
            <p></p>

            <h2>
                <p>
                    Organizații Pikler în întreaga lume
                </p>
            </h2>
            <p>
                <ul>
            <li style={{color:"#2f5078"}}>

                <a className="action-color-5" href="https://www.pikler.hu/pikler-organisations-worldwide">https://www.pikler.hu/pikler-organisations-worldwide</a>
            </li>
            </ul>
            </p>
            <br/>

            <h2>
                <p>
                    Scene scurte de îngrijire/relaționare după principiile Emmi Pikler (în engleză)
                </p>
            </h2>
            
            <ul>
            <p>
            <li style={{color:"#2f5078"}}>

            <a className="action-color-5" href="https://www.youtube.com/watch?v=7-2NAFgXiO0">https://www.youtube.com/watch?v=7-2NAFgXiO0</a>
            </li>
            </p>
            <p>
            <li style={{color:"#2f5078"}}>

            <a className="action-color-5" href="https://www.youtube.com/watch?v=aNQi9ARWrvU">https://www.youtube.com/watch?v=aNQi9ARWrvU</a>
            </li>
            </p>
            </ul>
            <h2>
            <br/>

                <p>
                    Video: dezvoltarea mișcărilor
                </p>
            </h2>
            <ul>
            <p>
                <li style={{color:"#2f5078"}}>
                <a className="action-color-5" href="https://www.youtube.com/watch?v=D9Ko7U1pLlg ">https://www.youtube.com/watch?v=D9Ko7U1pLlg </a> (rotirea)
                </li>
            </p>
            <p>
                <li style={{color:"#2f5078"}}>

                <a className="action-color-5" href="https://www.youtube.com/watch?v=14gWirURq6I ">https://www.youtube.com/watch?v=14gWirURq6I </a>  (târâtul și mersul de-a bușelea)
                </li>
            </p>
            </ul>
            <h2>
                <br/>
                <p>
                Scene dintr-un centru Pikler: trăirile unei grupe părinte-copil (în germană)
                </p>
            </h2>
           <ul>
           <p>
            <li style={{color:"#2f5078"}}>
            <a className="action-color-5" href="https://www.youtube.com/watch?v=8e2fr0QZnRI">https://www.youtube.com/watch?v=8e2fr0QZnRI</a>
                </li>

            </p>
           </ul>
           <br/>
            <h2>
                <p>
                Materialele Pikler originale <span style={{fontWeight:"normal"}}>oferă calitate stabilă, siguranță și bucuria de descoperire. În lume sunt doar doi producători certificați de către Pikler® Institute:</span>
                </p>
            </h2>
           <ul>
           <p>
            <li style={{color:"#2f5078"}}>
            <a className="action-color-5" href="https://basisgemeinde.de/">Basisgemeinde Wulfshagenerhütten eG </a>  (Germania)
                </li>
            </p><p>
            <li style={{color:"#2f5078"}}>
<a className="action-color-5" href="https://www.spielzeugmacher.at/home">Martin Plackner, der Spielzeugmacher</a>  (Austria, de unde sunt și materialele noastre)
                </li>
            </p>
           </ul>
           <br/>
            <h2>
                <p>
                Naturalețea și unicacitatea materialelor:
                </p>
            </h2>
           <ul>
           <p>
            <li style={{color:"#2f5078"}}>
                <a className="action-color-5" href="https://kokomoo.at/">https://kokomoo.at/</a>
                </li>
            </p>
           </ul>
           <ToTopBtn></ToTopBtn>
        </div>
    )
}