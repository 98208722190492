import { useState,useLayoutEffect } from "react";

export default function Image({src,desktopWidth,tabletWidth, phoneWidth,phoneMaxWidth}){
    const [mobile, setMobile] = useState(false);
    const [tablet, setTablet] = useState(false);

    useLayoutEffect(() => {
        setMobile(window.matchMedia("(max-width: 770px)").matches);
        window.addEventListener('resize', () => setMobile((window.matchMedia("(max-width: 770px)").matches)));
        return () => window.removeEventListener('resize', () => setMobile((window.matchMedia("(max-width: 770px)").matches)));
    }, []);

    useLayoutEffect(() => {
        setTablet(window.matchMedia("(max-width: 1100px)").matches);
        window.addEventListener('resize', () => setTablet((window.matchMedia("(max-width: 1100px)").matches)));
        return () => window.removeEventListener('resize', () => setTablet((window.matchMedia("(max-width: 1100px)").matches)));
    }, []);



    if( mobile){
        return(
            <>
                <img loading=" lazy" src={src} width={phoneWidth} style={{maxWidth:`${phoneMaxWidth}`,borderRadius:"0.5em",objectFit:"cover",alignSelf:"center"}}></img>
            </>
        )
    
    }else if(tablet){
        return(<>

            <img loading=" lazy" src={src} width={tabletWidth} style={{borderRadius:"0.5em",objectFit:"cover",alignSelf:"center"}}></img>
        </>)
    }
    else{
        return(<>
        <div style={{minWidth:`${desktopWidth}`,borderRadius:"0.5em", maxWidth:`${desktopWidth}`,alignSelf:"center"}}>
            <img loading=" lazy" src={src} width="100%" style={{borderRadius:"0.5em",objectFit:"cover"}}></img>
        </div>
        </>)
    }
}